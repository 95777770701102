import React, { Component } from 'react';
import Users from './screens/users';
import Subscriptions from './screens/subscriptions';
import Customers from './screens/customers';
import CustomerManagement from './screens/customerManagement';
import SubscriptionManagement from './screens/subscriptionManagement';
import Reporting from './screens/reporting';
import Register from './screens/register';
import Reset from './screens/reset';
import Login from './screens/login';
import Profile from './screens/profile';
import Payments from './screens/payments';
import LoadingScreen from './screens/loading';
import { Client, Staff, Admin, CompanyAdmin } from './screens/home';
import Navigation from './components/navigation';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog'
import { faBan } from '@fortawesome/pro-light-svg-icons/faBan'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faSearchPlus } from '@fortawesome/pro-light-svg-icons/faSearchPlus'
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash'
import { faChartLine } from '@fortawesome/pro-solid-svg-icons/faChartLine'
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faHouse } from '@fortawesome/pro-regular-svg-icons/faHouse'
import { faList } from '@fortawesome/pro-regular-svg-icons/faList'
import { faListAlt } from '@fortawesome/pro-regular-svg-icons/faListAlt'
import { faBuilding } from '@fortawesome/pro-regular-svg-icons/faBuilding'
import { faCreditCardFront } from '@fortawesome/pro-regular-svg-icons/faCreditCardFront'
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import './scss/app.scss';
import Container from 'react-bootstrap/Container'
import { connect } from 'react-redux'
import { checkLogin } from 'actions/loginActions'
import 'utils/config'
import { hideConfirmation } from 'actions/globalActions'

library.add(faPencil, faPlus, faCog, faBan, faTimes, faSearchPlus, faTrash, faChartLine, faLock, faUser, faEnvelope, faHouse, faListAlt, faList, faBuilding, faCreditCardFront, faCheck)

class App extends Component {
  constructor(props) {
    super(props)

    this.renderRouter = this.renderRouter.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(checkLogin());
  }

  componentDidUpdate(prevProps) {
    if (this.props.confirmationMessage && prevProps.confirmationMessage !== this.props.confirmationMessage) {
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.props.dispatch(hideConfirmation());
      }, 3000)
    }
  }

  homeScreen() {
    const { loggedInUser } = this.props

    switch (loggedInUser.role) {
      case 0:
        return <Client user={loggedInUser} />
      case 1:
        return <Staff user={loggedInUser} />
      case 2:
        return <CompanyAdmin user={loggedInUser} />
      case 3:
        return <Admin user={loggedInUser} />
      default:
        return null;
    }
  }

  renderRouter() {
    const { loggedInUser, checkingLogin } = this.props

    if (checkingLogin) return <LoadingScreen/>

    if (Object.keys(loggedInUser).length > 0) {
      return (
        <Router>
          <Navigation user={loggedInUser} />

          <Switch>
            <Route path="/subscriptions/:id">
              <Container>
                <SubscriptionManagement />
              </Container>
            </Route>
            <Route path="/subscriptions">
              <Container>
                <Subscriptions />
              </Container>
            </Route>
            <Route path="/reporting">
              <Container>
                <Reporting />
              </Container>
            </Route>
            <Route path="/admin-users">
              <Container>
                <Users />
              </Container>
            </Route>
            <Route path="/customers/:id">
              <Container>
                <CustomerManagement />
              </Container>
            </Route>
            <Route path="/customers">
              <Container>
                <Customers />
              </Container>
            </Route>
            <Route path="/payments">
              <Container>
                <Payments />
              </Container>
            </Route>
            <Route path="/profile">
              <Container>
                <Profile />
              </Container>
            </Route>
            <Route path="/">
              <Container>
                {this.homeScreen()}
              </Container>
            </Route>
          </Switch>
        </Router>
      );
    }

    return (
      <Router>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/reset-password">
            <Reset />
          </Route>
          <Route path="/set-password/:token">
            <Register />
          </Route>
          <Route path="/">
            <Login />
          </Route>
        </Switch>
      </Router>
    )
  }

  render() {
    const { confirmationMessage, loggedInUser, checkingLogin } = this.props
    
    return (
      <div className={checkingLogin ? 'centered-content-container' : (Object.keys(loggedInUser).length > 0 ? 'fade-in logged-in-container' : 'logged-out-background centered-content-container fade-in')}>
        <div className={confirmationMessage ? 'confirmation-message show' : 'confirmation-message hide'}>{confirmationMessage}</div>
        {this.renderRouter()}
      </div>
    )
  }
}

export default connect(state => ({
  checkingLogin: state.loginReducer.checkingLogin,
  loggedInUser: state.loginReducer.loggedInUser,
  confirmationMessage: state.globalReducer.confirmationMessage,
}))(App);
