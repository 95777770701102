import React from 'react';

const Loader = ({children, size}) => {
  return (
    <div className={`loader-container ${size}`}>
      <div className="loader">
        <div className="loader-dot"></div>
        <div className="loader-dot"></div>
        <div className="loader-dot"></div>
        <div className="loader-dot"></div>
        <div className="loader-dot"></div>
        <div className="loader-dot"></div>
      </div>
      {children}
    </div>
  )
}

export default Loader