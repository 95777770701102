import React, { Component } from 'react'
import _ from 'lodash'
import helper from 'helpers/global'

export class GoogleDataChart extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true
    }
  }

  updateDimensions() {
    this.chart.execute();
  }

  componentDidMount() {
    this.loadChart();
    window.addEventListener("resize", helper.debounce(this.updateDimensions.bind(this)));
  }

  componentDidUpdate(prevProps) {
    // console.log(!_.isEqual(prevProps.config, this.props.config))
    if (!_.isEqual(prevProps.config, this.props.config)) {
      this.setState({loading: true})
      this.loadChart();
    }
  }

  loadChart = () => {
    const config = {
      ...this.props.config,
      chart: {
        ...this.props.config.chart,
        container: this.chartNode
      }
    };
    this.chart = new window.gapi.analytics.googleCharts.DataChart(config);
    this.chart.set(this.props.views).on('success', () => this.setState({loading: false})).execute();
  };

  loading = () => {
    return this.props.loading || this.state.loading
  };

  render() {
    return (
      <div>
        {this.loading() ? this.props.loadingComponent : null}
        <div
          className={`${this.props.className ? this.props.className : ''} ${this.loading() ? 'invisible' : ''}`}
          style={this.props.style}
          ref={node => (this.chartNode = node)}
        >
        </div>
      </div>
    );
  }
}