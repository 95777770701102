import { combineReducers } from 'redux';
import activationReducer from './activationReducer';
import loginReducer from './loginReducer';
import profileReducer from './profileReducer';
import reportingReducer from './reportingReducer';
import globalReducer from './globalReducer';
import modelReducers from './modelReducers';

const app = combineReducers({
    activationReducer,
    loginReducer,
    reportingReducer,
    globalReducer,
    profileReducer,
    ...modelReducers
});

export default app;
