import actionTypes from 'actionTypes/reportingTypes';
import handleAxiosError from 'helpers/axiosHelpers'
import axios from 'axios';

export function getReportsAccess() {
  return async dispatch => {
    dispatch({
      type: actionTypes.FETCHING_REPORTS
    });

    try {
      const response = await axios.get('/application/Reporting')

      dispatch({
        type: actionTypes.FETCHING_REPORTS_COMPLETE,
        accessToken: response.data.accessToken
      });
    } catch (e) {
      handleAxiosError(e, dispatch, actionTypes.REPORTS_ERROR)
    }
  }
}