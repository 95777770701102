import React, { Component } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from 'components/loader'

class Loading extends Component {

  render() {
    return (
      <div className="centered-content-container">
        <Container>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <div className="content-block">
                <Loader size="large"><h2>Loading</h2></Loader>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Loading;