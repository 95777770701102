import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModelView from 'components/modelView'
import { recordStatus, enumsForSelect, settings } from 'utils/config';
import ModelForm from 'components/modelForm'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {
  useHistory
} from "react-router-dom";
import { format } from 'date-fns'
import _ from 'lodash'

/*
* Admin Homes
*/

const Admin = ({user}) => {
  let history = useHistory();

  return (
    <>
      <div className="page-header home-header">
        <h1>Dashboard</h1>
        <div>Welcome, <b>{user.firstName}</b></div>
      </div>
      
      <Row>
        <Col xs={12} md={4}>
          <div className="grid-block">
            <h3><FontAwesomeIcon icon={['far', 'list-alt']} />Subscriptions</h3>
            <Button variant="success" onClick={() => { history.push('/subscriptions') }} >View Subscriptions</Button>
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="grid-block">
            <h3><FontAwesomeIcon icon={['fas', 'chart-line']} />Reporting</h3>
            <Button variant="success" onClick={() => { history.push('/reporting') }} >View Available Reports</Button>
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="grid-block">
            <h3><FontAwesomeIcon icon={['fas', 'lock']} />Admin Users</h3>
            <Button variant="success" onClick={() => { history.push('/admin-users') }} >View Admin Users</Button>
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="grid-block">
            <h3><FontAwesomeIcon icon={['far', 'building']} />Customers</h3>
            <Button variant="success" onClick={() => { history.push('/payments') }} >View Customers</Button>
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="grid-block">
            <h3><FontAwesomeIcon icon={['far', 'credit-card-front']} />Payments</h3>
            <Button variant="success" onClick={() => { history.push('/payments') }} >View Transaction History</Button>
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="grid-block">
            <h3><FontAwesomeIcon icon={['fas', 'user']} />Profile</h3>
            <Button variant="success" onClick={() => { history.push('/profile') }} >View Profile</Button>
          </div>
        </Col>
      </Row>

      <AdminRequests/>
    </>
  )
}

const Staff = ({user}) => {
  let history = useHistory();

  return (
    <>
      <div className="page-header home-header">
        <h1>Dashboard</h1>
        <div>Welcome, <b>{user.firstName}</b></div>
      </div>
      
      <Row>
        <Col xs={12} md={4}>
          <div className="grid-block">
            <h3><FontAwesomeIcon icon={['far', 'list-alt']} />Subscriptions</h3>
            <Button variant="success" onClick={() => { history.push('/subscriptions') }} >View Subscriptions</Button>
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="grid-block">
            <h3><FontAwesomeIcon icon={['fas', 'chart-line']} />Reporting</h3>
            <Button variant="success" onClick={() => { history.push('/reporting') }} >View Available Reports</Button>
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="grid-block">
            <h3><FontAwesomeIcon icon={['far', 'building']} />Customers</h3>
            <Button variant="success" onClick={() => { history.push('/payments') }} >View Customers</Button>
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="grid-block">
            <h3><FontAwesomeIcon icon={['far', 'credit-card-front']} />Payments</h3>
            <Button variant="success" onClick={() => { history.push('/payments') }} >View Transaction History</Button>
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="grid-block">
            <h3><FontAwesomeIcon icon={['fas', 'user']} />Profile</h3>
            <Button variant="success" onClick={() => { history.push('/profile') }} >View Profile</Button>
          </div>
        </Col>
      </Row>

      <AdminRequests/>
    </>
  )
}

const AdminRequests = () => (
  <ModelView 
    header="Service Queue List"
    copy={`The following items are currently in queue. Please mark "Confirm Enable" or "Confirm Disable" as they are added to or removed from the website.`}
    model="locationSubscription"
    pluralModel="locationSubscriptions"
    disableEdit={true}
    headers={['Customer Name', 'Location Name', 'Destination', 'Status', 'Next Payment']}
    label="pending request"
    toggleText={data => data.status === 0 ? 'Confirm Enable' : 'Confirm Disable'}
    toggleChange={data => {
      data.pending = false
      return data
    }}
    params="pending=true"
    cells={[
      (item) => item.location.company.name,
      (item) => item.location.name,
      (item) => item.location.destination.name,
      (item) => <div className="status-container"><div className={`status-circle bg-${item.pending ? 'warning' : (item.status === 0 ? 'success' : 'danger')}`}></div>{item.pending ? 'Pending' : ''} {recordStatus[item.status]}</div>,
      (item) => item.nextPaymentDate ? format(new Date(item.nextPaymentDate), 'MMMM d, yyyy') : 'Cancelled',
    ]}
  />
);

/*
* Customer Homes
*/

const CompanyAdmin = ({user}) => {
  let history = useHistory();
  let tabs = ['edit', 'paymentMethods']
  let active = tabs.includes(history.location.hash.split('#')[1]) ? history.location.hash.split('#')[1] : 'edit'

  return (
    <>
      <div className="page-header home-header">
        <h1>Dashboard</h1>
        <div>Welcome, <b>{user.firstName}</b></div>
      </div>
      { user.userCompanies.length > 0 ? (
        <Tabs defaultActiveKey={active}>
          <Tab eventKey="edit" title="Company Information">
            <ModelForm 
              item={user.userCompanies[0].company}
              label="Company"
              fields={[
                [
                  {name: 'Name', placeholder: 'Company Name'},
                  {name: 'Email'}
                ],
                [
                  {name: 'Phone', mask: "(###) ###-####"},
                  {name: 'Status', as: 'radio-buttons', ...enumsForSelect(recordStatus)}
                ],
                {name: 'Address Information', as: 'section'},
                [
                  {name: 'Line 1', attributeName: 'Address.addressLine1', placeholder: 'Address Line 1'},
                  {name: 'Line 2', attributeName: 'Address.addressLine2', placeholder: 'Address Line 2'}
                ],
                [
                  {name: 'City', attributeName: 'Address.city'},
                  {name: 'State', attributeName: 'Address.stateId', as: 'select', options: '/application/states', optionValue: s => s.id, optionLabel: s => s.name}
                ],
                [
                  {name: 'Zip Code', attributeName: 'Address.zipCode'},
                  {name: 'Contact', attributeName: 'UserId', as: 'select', options: '/users/all', optionValue: u => u.id, optionLabel: u => `${u.firstName} ${u.lastName} (${u.email})`}
                ]
              ]}
              changeMessage="In order to save, at least one field must be changed."
              model="company"
              pluralModel="companies"
              submit="update"
            />
          </Tab>
          <Tab eventKey="paymentMethods" title="Payment Methods">
            <ModelView 
              label="Payment Method"
              model="paymentMethod"
              pluralModel="paymentMethods"
              headers={['Brand', 'Last 4', 'Expiration', 'Subscriptions']}
              containerClass=""
              parentId={user.userCompanies[0].companyId}
              cells={[
                (item) => _.startCase(item.brand),
                (item) => item.lastFour,
                (item) => `${item.expirationMonth}/${item.expirationYear}`,
                (item) => item.locationSubscriptions ? item.locationSubscriptions.length : 0,
              ]}
              disableEdit={true}
              showDelete={true}
              disableDelete={(item) => item.locationSubscriptions ? item.locationSubscriptions.length > 0 : false}
              fields={[
                {name: 'Card', attributeName: 'self', as: 'card'},
                {name: 'Billing Address', as: 'section'},
                {name: 'Line 1', attributeName: 'Address.addressLine1', placeholder: 'Address Line 1'},
                {name: 'Line 2', attributeName: 'Address.addressLine2', placeholder: 'Address Line 2'},
                [
                  {name: 'City', attributeName: 'Address.city'},
                  {name: 'State', attributeName: 'Address.stateId', as: 'select', options: '/application/states', optionValue: s => s.id, optionLabel: s => s.name},
                ],
                [
                  {name: 'Zip Code', attributeName: 'Address.zipCode'},
                ],
              ]}
            />
          </Tab>
        </Tabs>
      ) : null}

      <Row className="mt-4">
        <Col xs={12} md={4}>
          <div className="grid-block">
            <h3><FontAwesomeIcon icon={['far', 'list-alt']} />Subscriptions</h3>
            <Button variant="success" onClick={() => { history.push('/subscriptions') }} >View Subscriptions</Button>
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="grid-block">
            <h3><FontAwesomeIcon icon={['far', 'credit-card-front']} />Payments</h3>
            <Button variant="success" onClick={() => { history.push('/payments') }} >View Transaction History</Button>
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="grid-block">
            <h3><FontAwesomeIcon icon={['fas', 'user']} />Profile</h3>
            <Button variant="success" onClick={() => { history.push('/profile') }} >View Profile</Button>
          </div>
        </Col>
      </Row>

      <UserRequests user={user}/>
    </>
  )
}

const Client = ({user}) => {
  let history = useHistory();

  return (
    <>
      <div className="page-header home-header">
        <h1>Dashboard</h1>
        <div>Welcome, <b>{user.firstName}</b></div>
      </div>
      <Row>
        <Col xs={12} md={4}>
          <div className="grid-block">
            <h3><FontAwesomeIcon icon={['far', 'list-alt']} />Subscriptions</h3>
            <Button variant="success" onClick={() => { history.push('/subscriptions') }} >View Subscriptions</Button>
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="grid-block">
            <h3><FontAwesomeIcon icon={['far', 'credit-card-front']} />Payments</h3>
            <Button variant="success" onClick={() => { history.push('/payments') }} >View Transaction History</Button>
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="grid-block">
            <h3><FontAwesomeIcon icon={['fas', 'user']} />Profile</h3>
            <Button variant="success" onClick={() => { history.push('/profile') }} >View Profile</Button>
          </div>
        </Col>
      </Row>

      <UserRequests/>
    </>
  )
}

const UserRequests = () => (
  <ModelView 
    header="Service Queue List"
    copy="The following items are currently in our queue. If these subscription requests were made in error, please use the buttons below to email us."
    model="locationSubscription"
    pluralModel="locationSubscriptions"
    disableEdit={true}
    label="pending request"
    headers={['Location Name', 'Next Payment', 'Subscription Plan', 'Status', 'Destination']}
    actionButtons={item => (
      <Button size="sm" variant="danger" href={`mailto:${settings.adminEmail}?subject=Cancel Request: ${item.status === 0 ? 'Enable' : 'Disable'} ${item.subscription.name} for ${item.location.company.name} - ${item.location.name}`}>
        <FontAwesomeIcon size="sm" icon={['fas', 'envelope']} /> Request {item.status === 0 ? 'Disable' : 'Enable'}
      </Button>
    )}
    params="pending=true"
    cells={[
      (item) => item.location.name,
      (item) => item.nextPaymentDate ? format(new Date(item.nextPaymentDate), 'MMMM d, yyyy') : 'Cancelled',
      (item) => item.subscription.name,
      (item) => <div className="status-container"><div className={`status-circle bg-${item.pending ? 'warning' : (item.status === 0 ? 'success' : 'danger')}`}></div>{item.pending ? 'Pending' : ''} {recordStatus[item.status]}</div>,
      (item) => item.location.destination.name,
    ]}
  />
);


export {
  Staff,
  Admin,
  Client,
  CompanyAdmin
}