import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from 'react-bootstrap/Pagination'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Loader from 'components/loader'

const PaginationComponent = ({pageCount, currentPage, setPage, setPerPage, perPage, loading, rowCount, filter, bottom = false}) => {
  var pages = pagination(currentPage, pageCount);
  var perPageOptions = [5, 10, 20, 50];
  var first = 1;
  var last = pages[pages.length - 1];

  return (
    <Row className="align-items-center mb-3 pagination-row">
      <Col lg="auto">
        {pages.length > 1 && rowCount > 0 ? (
          <Pagination className="mb-0">
            <Pagination.First onClick={() => setPage(first)} disabled={currentPage === first} />
            <Pagination.Prev onClick={() => setPage(currentPage - 1)} disabled={currentPage === first} />
            {pages.map((page, index) => {
              return page ? (
                <Pagination.Item key={index} active={page === currentPage} onClick={() => setPage(page)}>
                    {page}
                </Pagination.Item>
              ) : (
                <Pagination.Ellipsis key={index} />
              )
            })}
            <Pagination.Next onClick={() => setPage(currentPage + 1)} disabled={currentPage === last} />
            <Pagination.Last onClick={() => setPage(last)} disabled={currentPage === last} />
          </Pagination>
        ) : rowCount > 0 ? <div>Showing all {rowCount} entries</div> : null}
      </Col>
      <Col className={(loading && rowCount > 0) || (bottom && pages.length > 1) ? '' : 'd-none d-lg-block'}>
        {loading && rowCount > 0 ? (<Loader size="small"/>) : bottom && pages.length > 1 ? (<div className="text-center">Showing {perPage} of {rowCount} entries</div>) : null}
      </Col>
      {rowCount > 0 ? (<Col lg="3" className="text-right">
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>Per Page</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control as="select" custom value={perPage} onChange={(event) => setPerPage(event.target.value)}>
            {perPageOptions.map((option) => (
              <option key={option} value={option}>
                  {option}
              </option>
            ))}
          </Form.Control>
        </InputGroup>
      </Col>) : null}
      {filter ? <Col lg="3">{filter}</Col> : null}
    </Row>
  )
}

function pagination(c, m) {
  var current = c,
  last = m,
  delta = 2,
  left = current - delta,
  right = current + delta + 1,
  range = [],
  rangeWithDots = [],
  l;

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push(false);
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
}

export default PaginationComponent


