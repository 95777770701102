import loginActionTypes from '../actionTypes/loginTypes';

const overrides = {
  complete: function(model, dispatch, data) {
    if (model === 'user') {
      dispatch({
        type: loginActionTypes.UPDATE_LOGGED_IN_USER,
        users: data.results
      });
    }
  }
}

export default overrides