import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ProfileUser from '../components/profileUser';
import ProfilePassword from '../components/profilePassword';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { resetErrors } from 'actions/profileActions';
import { connect } from 'react-redux';

class Profile extends Component {
  componentWillUnmount() {
    this.props.dispatch(resetErrors());
  }

  render() {
    return (
      <div>
        <h1 className="page-header"><FontAwesomeIcon icon={['fas', 'user']} />Profile</h1>
        <Tabs defaultActiveKey="info">
          <Tab eventKey="info" title="User Information">
            <ProfileUser />
          </Tab>
          <Tab eventKey="password" title="Change Password">
            <ProfilePassword />
          </Tab>
        </Tabs>
      </div>
    )
  }
}

export default connect()(Profile);