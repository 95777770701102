import React, { Component } from 'react'
import ModelView from 'components/modelView'
import { recordStatus, subscriptionInterval, subscriptionType, enumsForSelect } from 'utils/config';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import actions from 'actions/modelActions'
import LoadingScreen from 'screens/loading';
import { format } from 'date-fns'
import _ from "lodash";

class SubscriptionManagement extends Component {
  componentDidMount() {
    if (this.props.subscriptions.length === 0) {
      this.props.dispatch(actions.subscription.fetch(1, 10, this.props.match.params.id))
    }
  }

  render() {
    const { subscriptions } = this.props;
    const plan = subscriptions.find(plan => plan.id === parseInt(this.props.match.params.id))

    if (!plan) return (
      <div className="mt-5">
        <LoadingScreen/>
      </div>
    );

    return (
      <ModelView 
        icon={['far', 'list']}
        title={"Subscriptions Management"}
        label="Location Subscription"
        header={`${plan ? plan.name : ''} Plan Subscriptions`}
        model="locationSubscription"
        pluralModel="locationSubscriptions"
        editSettings={item => {
          if (item) {
            return {
              buttonLabel: 'Payment Method',
              modalLabel: 'Select a Payment Method',
              fields: [
                {
                  name: 'Payment Method', 
                  attributeName: 'PaymentMethodId',
                  as: 'select', 
                  options: `/companies/${item.location.companyId}/paymentMethods/all`, 
                  optionValue: u => u.id, 
                  optionLabel: u => `${_.startCase(u.brand)} - ${u.lastFour} (Exp ${u.expirationMonth}/${u.expirationYear})`
                }
              ]
            }
          } else {
            return {}
          }
        }}
        headers={['Customer Name', 'Location Name', 'Destination', 'Status', 'Next Payment']}
        newLabel="Subscribe a location"
        parentId={plan.id}
        defaultItem={{firstPaymentDate: new Date(), status: '0'}}
        editableParent={{
          item: plan,
          model: 'subscription',
          pluralModel: 'subscriptions',
          label: 'Subscription',
          fields: [
            [
              {name: 'Name'},
              {name: 'Cost'}
            ],
            [
              {name: 'Interval', attributeName: 'SubscriptionInterval', as: 'select', ...enumsForSelect(subscriptionInterval, true)},
              {name: 'Frequency', attributeName: 'SubscriptionIntervalCount'}
            ],
            [
              {name: 'Type', attributeName: 'SubscriptionType', as: 'select', ...enumsForSelect(subscriptionType, true)},
              {name: 'Status', as: 'radio-buttons', ...enumsForSelect(recordStatus)}
            ]
          ]
        }}
        cells={[
          (item) => item.location.company.name,
          (item) => item.location.name,
          (item) => item.location.destination.name,
          (item) => <div className="status-container"><div className={`status-circle bg-${item.pending ? 'warning' : (item.status === 0 ? 'success' : 'danger')}`}></div>{item.pending ? 'Pending' : ''} {recordStatus[item.status]}</div>,
          (item) => item.nextPaymentDate ? format(new Date(item.nextPaymentDate), 'MMMM d, yyyy') : 'Cancelled',
        ]}
        fields={[
          {name: 'General Information', as: 'section'},
          {name: 'Location', objectName: 'location', attributeName: 'LocationId', as: 'select', options: '/locations/all', optionValue: u => u.id, optionLabel: u => `${u.company.name} - ${u.name}`},
          [
            {name: 'Start Date', attributeName: 'FirstPaymentDate', placeholder: 'First Payment Date', as: 'date'},
            {name: 'Status', as: 'radio-buttons', ...enumsForSelect(recordStatus)}
          ],
          {name: 'Billing Information', as: 'section'},
          {
            name: 'Payment Method', 
            attributeName: 'PaymentMethodId',
            as: 'select', 
            defaultOption: {label: '-- Create New Payment Method --', id: ''}, 
            options: item => item.location ? `/companies/${item.location.companyId}/paymentMethods/all` : [], 
            optionValue: u => u.id, 
            optionLabel: u => `${_.startCase(u.brand)} - ${u.lastFour} (Exp ${u.expirationMonth}/${u.expirationYear})`,
          },
          [
            {name: 'Line 1', attributeName: 'PaymentMethod.address.addressLine1', placeholder: 'Address Line 1', show: item => !item.paymentMethodId},
            {name: 'Line 2', attributeName: 'PaymentMethod.address.addressLine2', placeholder: 'Address Line 2', show: item => !item.paymentMethodId}
          ],
          [
            {name: 'City', attributeName: 'PaymentMethod.address.city', show: item => !item.paymentMethodId},
            {name: 'State', attributeName: 'PaymentMethod.address.stateId', as: 'select', options: '/application/states', optionValue: s => s.id, optionLabel: s => s.name, show: item => !item.paymentMethodId}
          ],
          [
            {name: 'Zip Code', attributeName: 'PaymentMethod.address.zipCode', show: item => !item.paymentMethodId},
            {name: 'Card', attributeName: 'PaymentMethod', as: 'card', show: item => !item.paymentMethodId}
          ]
        ]}
      />
    )
  }
};

export default connect(state => ({
  subscriptions: state.subscriptionsReducer.results,
  loading: state.subscriptionsReducer.loading,
}))(withRouter(SubscriptionManagement));
