import ActionTypes from '../actionTypes/activationTypes';

const initialState = {
  fetchingToken: false,
  activating: false,
  user: null,
  activationError: false,
  invalidToken: false,
  requestingReset: false,
  requestingResetError: false
};

function activationReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ACTIVATION_LOADING: {
      return { ...state, activationError: false, activating: true };
    }
    case ActionTypes.ACTIVATION_COMPLETE: {
      return { ...state, user: action.user, activating: false, activationError: false };
    }
    case ActionTypes.ACTIVATION_ERROR: {
      return { ...state, activationError: action.data, activating: false };
    }
    case ActionTypes.FETCHING_TOKEN: {
      return { ...state, invalidToken: false, fetchingToken: true };
    }
    case ActionTypes.FETCHING_TOKEN_COMPLETE: {
      return { ...state, invalidToken: false, fetchingToken: false };
    }
    case ActionTypes.TOKEN_ERROR: {
      return { ...state, invalidToken: true, fetchingToken: false };
    }
    case ActionTypes.RESET_ACTIVATION_ERRORS: {
      return { ...state, activationError: false, invalidToken: false };
    }
    case ActionTypes.REQUESTING_RESET: {
      return { ...state, requestingReset: true };
    }
    case ActionTypes.REQUESTING_RESET_ERROR: {
      return { ...state, requestingReset: false, requestingResetError: action.data };
    }
    case ActionTypes.REQUESTING_RESET_COMPLETE: {
      return { ...state, requestingReset: false, requestingResetError: false };
    }
    default:
      return state;
  }
}

export default activationReducer;
