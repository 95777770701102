import React, { Component } from 'react'
import ModelView from 'components/modelView'
import { recordStatus, userRoles, enumsForSelect } from 'utils/config';

class Users extends Component {
  render() {
    return (
      <ModelView
        icon={['fas', 'lock']}
        title="Admin User Management"
        header="Users List"
        label="User"
        model="user"
        pluralModel="users"
        headers={['Name', 'Email Address', 'Status', 'Role Assigned']}
        cells={[
          (item) => `${item.firstName} ${item.lastName}`,
          (item) => item.email,
          (item) => <div className="status-container"><div className={`status-circle bg-${item.status === 0 ? 'success' : 'danger'}`}></div>{recordStatus[item.status]}</div>,
          (item) => userRoles[item.role],
        ]}
        fields={[
          [
            {name: 'First Name'},
            {name: 'Last Name'}
          ],
          [
            {name: 'User Name'},
            {name: 'Phone', mask: "(###) ###-####"}
          ],
          {name: 'Email', type: 'email'},
          [
            {name: 'Role', as: 'select', ...enumsForSelect(userRoles)},
            {name: 'Status', as: 'radio-buttons', ...enumsForSelect(recordStatus)}
          ]
        ]}
      />
    )
  }
};

export default Users;
