import actionTypes from '../actionTypes/modelTypes';
import globalTypes from '../actionTypes/globalTypes';
import handleAxiosError from 'helpers/axiosHelpers'
import overrides from 'helpers/modelActionOverrides'
import axios from 'axios';
import models from 'utils/config'
import { recordStatus } from 'utils/config';
import lodash from 'lodash';

const modelActions = {}

function getParentRoute(options, parentId) {
  if (options.parent && parentId) {
    return `/${models[options.parent].plural}/${parentId}`
  }

  return '';
}

Object.entries(models).forEach(([model, options]) => {
  const plural = options.plural;
  modelActions[model] = {}

  modelActions[model].fetch = function(page = 1, pageSize = 10, recordId = '', parentId = null, params = '', append = false) {
    return async dispatch => {
      dispatch({
        type: actionTypes[model].FETCHING
      });

      try {
        const response = await axios.get(`${getParentRoute(options, parentId)}/${plural}?page=${page}&pageSize=${pageSize}&recordId=${recordId}&${params}`);

        dispatch({
          type: actionTypes[model].FETCHING_COMPLETE,
          ...response.data,
          append: append
        });

        if (overrides.complete) overrides.complete(model, dispatch, response.data)
      } catch (e) {
        handleAxiosError(e, dispatch, actionTypes[model].FETCHING_ERROR)
      }
    };
  }

  modelActions[model].update = function(data, page = 1, pageSize = 10, parentId = null, params = '') {
    return async dispatch => {
      dispatch({
        type: actionTypes[model].FETCHING
      });

      try {
        if (options.parent && parentId) {
          data[`${options.parent}Id`] = parentId;
        }

        const response = await axios.put(`${getParentRoute(options, parentId)}/${plural}/${data.id}?page=${page}&pageSize=${pageSize}&${params}`, data);

        dispatch({
          type: actionTypes[model].FETCHING_COMPLETE,
          ...response.data
        });

        dispatch({
          type: globalTypes.SET_CONFIRMATION_MESSAGE,
          message: `Updated ${lodash.lowerCase(model)}`
        });

        if (overrides.complete) overrides.complete(model, dispatch, response.data)
      } catch (e) {
        handleAxiosError(e, dispatch, actionTypes[model].FETCHING_ERROR)
      }
    };
  }

  modelActions[model].add = function(data, page = 1, pageSize = 10, parentId = null, params = '') {
    return async dispatch => {
      dispatch({
        type: actionTypes[model].FETCHING
      });

      try {
        if (options.parent && parentId) {
          data[`${options.parent}Id`] = parentId;
        }

        const response = await axios.post(`${getParentRoute(options, parentId)}/${plural}?page=${page}&pageSize=${pageSize}&${params}`, data);

        dispatch({
          type: actionTypes[model].FETCHING_COMPLETE,
          ...response.data
        });

        dispatch({
          type: globalTypes.SET_CONFIRMATION_MESSAGE,
          message: `Created ${lodash.lowerCase(model)}`
        });

        if (overrides.complete) overrides.complete(model, dispatch, response.data)
      } catch (e) {
        handleAxiosError(e, dispatch, actionTypes[model].FETCHING_ERROR)
      }
    };
  }

  modelActions[model].delete = function(data, page = 1, pageSize = 10, parentId = null, params = '') {
    return async dispatch => {
      dispatch({
        type: actionTypes[model].FETCHING
      });

      try {
        const response = await axios.delete(`${getParentRoute(options, parentId)}/${plural}/purge/${data.id}?page=${page}&pageSize=${pageSize}&${params}`);

        dispatch({
          type: actionTypes[model].FETCHING_COMPLETE,
          ...response.data
        });

        dispatch({
          type: globalTypes.SET_CONFIRMATION_MESSAGE,
          message: `Deleted ${lodash.lowerCase(model)}`
        });

        if (overrides.complete) overrides.complete(model, dispatch, response.data)
      } catch (e) {
        handleAxiosError(e, dispatch, actionTypes[model].FETCHING_ERROR)
      }
    };
  }

  modelActions[model].toggleStatus = function(data, page = 1, pageSize = 10, parentId = null, params = '') {
    return async dispatch => {
      dispatch({
        type: actionTypes[model].FETCHING
      });

      data.status = data.status === 0 ? 1 : 0; 

      try {
        if (options.parent && parentId) {
          data[`${options.parent}Id`] = parentId;
        }

        const response = await axios.put(`${getParentRoute(options, parentId)}/${plural}/${data.id}?page=${page}&pageSize=${pageSize}&${params}`, data);

        dispatch({
          type: actionTypes[model].FETCHING_COMPLETE,
          ...response.data
        });

        dispatch({
          type: globalTypes.SET_CONFIRMATION_MESSAGE,
          message: `${recordStatus[data.status]} ${lodash.lowerCase(model)}`
        });

        if (overrides.complete) overrides.complete(model, dispatch, response.data)
      } catch (e) {
        data.status = data.status === 0 ? 1 : 0; 
        
        handleAxiosError(e, dispatch, actionTypes[model].FETCHING_ERROR)
      }
    };
  }

  modelActions[model].setPage = function(page, pageSize, refresh = true, parentId = null, params = '', append = false) {
    return async dispatch => {
      dispatch({
        type: actionTypes[model].SET_PAGE,
        page
      });

      dispatch({
        type: actionTypes[model].SET_PAGE_SIZE,
        pageSize
      });

      if (refresh) {
        dispatch(modelActions[model].fetch(page, pageSize, '', parentId, params, append));
      }
    };
  }

  modelActions[model].loading = function() {
    return async dispatch => {
      dispatch({
        type: actionTypes[model].FETCHING
      });
    };
  }

  modelActions[model].setErrors = function(error) {
    return async dispatch => {
      dispatch({
        type: actionTypes[model].FETCHING_ERROR,
        data: {title: 'One or more validation errors occurred.', errors: error}
      });
    };
  }

  modelActions[model].resetErrors = function() {
    return async dispatch => {
      dispatch({
        type: actionTypes[model].RESET_ERRORS
      });
    };
  }
});

export default modelActions;
