import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton'
import Col from 'react-bootstrap/Col';
import Select from "react-select";
import axios from "axios";
import _ from "lodash";
import helper from 'helpers/global'
import { CardElement } from '@stripe/react-stripe-js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Input = ({
  name,
  disabled = false,
  data,
  item,
  errors,
  onChange = (option) => { },
  onInputChange = (option) => { },
  onSelectChange = (option) => { },
  singleUrl = false,
  type,
  column,
  options,
  as,
  attributeName = false,
  allowOption = () => true,
  objectName = false,
  placeholder = false,
  optionLabel = () => '',
  optionValue = () => '',
  className = '',
  label = true,
  clearable = false,
  mask = false,
  defaultOption = null
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [loadedOptions, setLoadedOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const selectOptions = _.isFunction(options) ? options(data, search) : options;
  const isDisabled = _.isFunction(disabled) ? disabled(data) : disabled;
  const attribute = attributeName ? attributeName : name.replace(/ /g, "");
  const lowerId = attribute.split('.').map(_.camelCase).join('.');
  const upperId = lowerId.split('.').map(_.upperFirst).join('.');
  const fieldName = _.last(upperId.split('.'))
  placeholder = placeholder ? placeholder : name;

  const segments = lowerId.split('.');
  const value = segments.reduce((acc, value) => {
    return acc[value] !== null && acc[value] !== undefined ? acc[value] : '';
  }, data);
  const originalValue = item ? segments.reduce((acc, value) => {
    return acc[value] !== null && acc[value] !== undefined ? acc[value] : '';
  }, item) : null;

  var error = errors[upperId] ? errors[upperId].join(' ').replace(new RegExp(fieldName, 'g'), placeholder) : null;
  error = errors[lowerId] ? errors[lowerId].join(' ').replace(new RegExp(fieldName, 'g'), placeholder) : error;

  const singleRoute = _.isFunction(singleUrl) ? singleUrl(item, originalValue) : singleUrl;

  useEffect(() => {
    if (typeof selectOptions === 'string') {
      setIsLoading(true)

      axios.get(selectOptions).then((response) => {
        setLoadedOptions(response.data)
        setIsLoading(false)
      });
    }
  }, [selectOptions]);

  const prevSingleRouteRef = useRef();
  useEffect(() => {
    if (singleRoute && prevSingleRouteRef.current !== undefined) { // If a single route is set and it's not the initial value (since that's either blank or old)
      axios.get(singleRoute).then((response) => {
        setSelectedOption(response.data)
        setIsLoading(false)
      });
    }

    if (!prevSingleRouteRef.current && !singleRoute) {
      setSelectedOption(null)
    }

    prevSingleRouteRef.current = singleRoute;
    // eslint-disable-next-line
  }, [singleRoute, prevSingleRouteRef.current]);

  function unmaskedValue(value) {
    // Ensure that value is the same as whats showing
    const maskedValue = maskValue(mask, value).split('');
    const allowedChars = ["#", "*"];

    const unmaskedValue = mask.split('').map((char, index) => allowedChars.includes(char) ? maskedValue[index] : '').join('');

    onChange(lowerId, unmaskedValue);
  }

  function isNumeric(value) {
    return /^-{0,1}\d+$/.test(value);
  }

  function isLetter(value) {
    return /^-{0,1}[a-zA-Z]+$/.test(value);
  }

  function maskValue(mask, value) {
    var maskedValue = '';

    var maskArr = mask.split('');
    var valueArr = value.split('');

    var valIndex = 0;

    for (let maskIndex = 0; maskIndex < maskArr.length; maskIndex++) {
      const element = maskArr[maskIndex];
      if (valueArr[valIndex]) {
        while ((!isNumeric(valueArr[valIndex]) && !isLetter(valueArr[valIndex])) && valIndex < valueArr.length) {
          valIndex++;
        }

        switch (element) {
          case '#':
            maskedValue += isNumeric(valueArr[valIndex]) ? valueArr[valIndex] : ""
            valIndex++;
            break;
          case '*':
            maskedValue += isLetter(valueArr[valIndex]) ? valueArr[valIndex] : ""
            valIndex++;
            break;
          default:
            maskedValue += element;
        }
      }
    }

    return maskedValue;
  }

  function renderToggleButtons(options) {
    var toggleButtons = [];
    options.forEach(option => {
      toggleButtons.push(<ToggleButton key={option.key} variant="outline-secondary" value={option.key}>{option.value}</ToggleButton>);
    });
    return toggleButtons;
  }

  function renderInput() {
    if (as === 'card') {
      return (
        <CardElement className="form-control stripe-form-control"
          options={{
            style: {
              base: {
                fontSize: '15px',
                color: '#495057',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                fontWeight: 300,
                '::placeholder': {
                  color: '#6c757d',
                },
              },
              invalid: {
                color: '#dc3101',
              },
            },
          }}
        />
      );
    } else if (as === 'select') {
      var availableOptions = typeof selectOptions === 'string' ? _.compact(_.uniqBy(_.concat(loadedOptions, selectedOption), 'id')) : selectOptions;
      if (defaultOption) availableOptions.unshift(defaultOption)
      availableOptions = availableOptions.filter(allowOption)

      if (!isLoading && value && !availableOptions.some(option => value === optionValue(option)) && availableOptions.length > 0) {
        onChange(lowerId, null, objectName, null);
        onSelectChange(null, objectName);
      }

      return (
        <Select
          isClearable={clearable}
          className={error ? 'is-invalid search-select' : 'search-select'}
          classNamePrefix="search-select"
          isLoading={typeof selectOptions === 'string' && isLoading}
          getOptionValue={optionValue}
          getOptionLabel={o => o.label ? o.label : optionLabel(o)}
          onInputChange={(value, details) => {
            helper.debounce(() => {
              setSearch(value)
            })()
          }}
          isDisabled={isDisabled}
          options={availableOptions}
          value={availableOptions.find(o => optionValue(o) === value)}
          isOptionSelected={(option) => value === optionValue(option)}
          onChange={(option, details) => {
            if (details.action === 'select-option') {
              onChange(lowerId, optionValue(option), objectName, option);
              onSelectChange(option, objectName);
            }
            if (details.action === 'clear') {
              onChange(lowerId, null, objectName, null);
              onSelectChange(null, objectName);
            }
          }}
        />
      );
    } else if (selectOptions && as === "radio-buttons") {
      return (
        <ToggleButtonGroup className={error ? 'is-invalid' : ''} type="radio" name="options" value={value.toString()} onChange={(value) => onChange(lowerId, value)}>
          {renderToggleButtons(selectOptions)}
        </ToggleButtonGroup>
      )
    } else if (as === "date") {
      return (
        <DatePicker
          disabledKeyboardNavigation={true}
          disabled={isDisabled}
          placeholderText={placeholder}
          onChange={(value) => onChange(lowerId, value)}
          dateFormat="MMMM d, yyyy"
          selected={value}
          wrapperClassName={error ? 'is-invalid' : ''}
          className="form-control"
        />
      )
    } else {
      return (
        <Form.Control
          disabled={isDisabled}
          as={as}
          type={type}
          placeholder={placeholder}
          value={mask ? maskValue(mask, value) : value}
          isInvalid={!!error}
          onChange={(event) => mask ? unmaskedValue(event.target.value) : onChange(lowerId, event.target.value)}
          custom={!!as}
        />
      )
    }
  }

  return (
    <InputGroup sm="12" md="6" as={column ? Col : 'div'} className={className}>
      {label && as !== 'radio-buttons' ? (<InputGroup.Prepend>
        <InputGroup.Text>{name}</InputGroup.Text>
      </InputGroup.Prepend>) : null}
      {renderInput()}
      <Form.Control.Feedback type="invalid">
        {error}
      </Form.Control.Feedback>
    </InputGroup>
  )
}

export default Input