import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { connect } from 'react-redux'
import actions from 'actions/modelActions'
import helper from 'helpers/global'
import _ from 'lodash'
import Input from 'components/input'
import {ElementsConsumer, CardElement} from '@stripe/react-stripe-js';

class ModelForm extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.renderField = this.renderField.bind(this)

    if (props.item) {
      this.state = {...props.item}
    } else {
      this.state = {}
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.item, this.props.item)) {
      this.setState({...this.props.item});
    }
  }

  async handleSubmit(event) {
    event.preventDefault();

    const item = _.cloneDeep(this.state);
    const {dispatch, page, pageSize, submit, parentId, fields, model, stripe, elements, params, passableFields} = this.props;
    const sanitizeFields = passableFields ? passableFields.concat(fields) : fields;

    // Sanitizes data so that only fields (ID is always allowed) that are present in the form are passed to the server.
    const fieldNames = sanitizeFields.flat().map(f => f.attributeName ? f.attributeName : f.name.replace(/ /g, ""))
    const empty = {}
    fieldNames.forEach(f => {
      const name = f.charAt(0).toLowerCase() + f.slice(1);
      empty[name] = ''
    })
    const deepenedEmpty = helper.deepen(empty)
    const cardField = fields.flat().find(x => x.as === 'card');
    var formData = helper.pickKeys(item, deepenedEmpty)

    if (cardField && (!cardField.show || cardField.show(item))) {
      dispatch(actions[model].loading());

      var cardFieldName = cardField.attributeName ? cardField.attributeName : cardField.name.replace(/ /g, "")
      cardFieldName = cardFieldName.charAt(0).toLowerCase() + cardFieldName.slice(1);

      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (error) {
        dispatch(actions[model].setErrors({[cardFieldName]: [error.message]}));

        return false;
      } else {
        var cardValue;
        if (cardFieldName === 'self') {
          cardValue = {
            stripeCardId: paymentMethod.id,
            lastFour: paymentMethod.card.last4,
            brand: paymentMethod.card.brand,
            expirationMonth: paymentMethod.card.exp_month,
            expirationYear: paymentMethod.card.exp_year
          }
        } else {
          cardValue = helper.deepen({
            [cardFieldName]: {
              stripeCardId: paymentMethod.id,
              lastFour: paymentMethod.card.last4,
              brand: paymentMethod.card.brand,
              expirationMonth: paymentMethod.card.exp_month,
              expirationYear: paymentMethod.card.exp_year
            }
          })
        }

        formData = _.merge(formData, cardValue)
      }
    }

    dispatch(actions[model][submit](formData, page, pageSize, parentId, params));
  }

  onInputChange(name, value, objectName, objectValue) {
    var oldState = _.cloneDeep(this.state)
    var changedState;
    if (objectName) {
      oldState = _.merge(oldState, helper.deepen({ [objectName]: null }))
      changedState = helper.deepen({ [name]: value, [objectName]: objectValue })
    } else {
      changedState = helper.deepen({ [name]: value })
    }
    this.setState(_.merge(oldState, changedState))
  }

  renderField(field, column = false) {
    const {error} = this.props;
    const errors = error && error.errors ? error.errors : {}

    if (field.as === 'section') {
      return (
        <h2 className="form-section-header">{field.name}</h2>
      );
    } else {
      return (
        <Input key={field.name} {...field} column={column} data={this.state} item={this.props.item} errors={errors} onChange={this.onInputChange} />
      );
    }
  }

  render() {
    const {item, error, loading, fields, changeMessage, footerClassName, bodyClassName, footerButtons} = this.props;

    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <div className={bodyClassName}>
            {error && error.title ? <p className="text-danger">{error.title}</p> : null}

            {fields.filter(f => Array.isArray(f) ? f.some(f => !f.show || f.show(this.state)) : !f.show || f.show(this.state)).map((field, index) => Array.isArray(field) ? (
              <Form.Group key={index}>
                <Form.Row>
                  {field.filter(f => !f.show || f.show(this.state)).map(subField => this.renderField(subField, true))}
                </Form.Row>
              </Form.Group>
            ) : (
              <Form.Group key={index}>
                {this.renderField(field)}
              </Form.Group>
            ))}
          </div>
          <div className={footerClassName}>
            {footerButtons}
            {_.isEqual(item, this.state) || loading ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    {loading ? 'Loading...' : changeMessage}
                  </Tooltip>
                }
              >
                <div>
                  <Button variant="primary" disabled className={loading ? 'loading' : ''} style={{ pointerEvents: 'none' }} onClick={this.handleSubmit}>
                    Save Changes
                  </Button>
                </div>
              </OverlayTrigger>
            ) : (
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            )}
          </div>
        </Form>
      </div>
    )
  }
}

ModelForm.defaultProps = {
  footerClassName: 'form-footer',
  params: ''
}

const InjectedForm = (props) => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <ModelForm stripe={stripe} elements={elements} {...props} />
    )}
  </ElementsConsumer>
);

export default connect((state, props) => ({
  ...state[`${props.pluralModel}Reducer`]
}))(InjectedForm);
