import React, { Component } from 'react'
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import { requestPasswordReset } from 'actions/activationActions'
import Form from 'react-bootstrap/Form';
import Input from 'components/input';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import {
  withRouter,
  Link
} from "react-router-dom";

class Reset extends Component {
  constructor(props) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      data: '',
      requested: false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.requestingReset && !this.props.requestingReset && !this.props.requestingResetError) {
      this.setState({requested: true})
    }
  }

  handleSubmit(event) {
    const data = this.state;

    this.props.dispatch(requestPasswordReset(data));
    event.preventDefault();
  }

  onInputChange(name, value) {
    this.setState({ [name]: value })
  }

  render() {
    const { requestingReset, requestingResetError } = this.props;
    const errors = requestingResetError && requestingResetError.errors ? requestingResetError.errors : {}
    
    return (
      <div className="centered-content-container">
        <Container>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <div className="content-block">
                <h1 className="logged-out-header">Reset Password</h1>
                <img
                  src={require("../Resources/Images/u6.png")}
                  alt="Main-Logo"
                  className="logged-out-logo"
                />
                {this.state.requested ? (
                  <div>
                    <h2>Request Received</h2>

                    <p>If the Username or Email you entered exists in our system, you should receive an email with a password reset link shortly.</p>

                    <Button variant="secondary" className={requestingReset ? 'loading' : ''} disabled={requestingReset} onClick={this.handleSubmit}>
                      Resend Email
                    </Button>
                    <Link className="float-right btn btn-primary" to="/login">Back to Login</Link>
                  </div>
                ) : (
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Group>
                      <Input attributeName="data" name="Email" placeholder="Username or Email" type="text" data={this.state} onChange={this.onInputChange} errors={errors} />
                    </Form.Group>
                    <Button variant="primary" type="submit" className={requestingReset ? 'loading' : ''} disabled={requestingReset}>
                      Reset Password
                    </Button>
                    <Link className="float-right" to="/login">Lost? Back to Login</Link>
                  </Form>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default connect(state => ({
  requestingReset: state.activationReducer.requestingReset,
  requestingResetError: state.activationReducer.requestingResetError
}))(withRouter(Reset))
