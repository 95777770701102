import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  useHistory,
  useLocation
} from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux'
import { logout } from 'actions/loginActions'

const Navigation = ({dispatch, user}) => {
  let history = useHistory();
  let location = useLocation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" onClick={() => setOpen(!open)} className="d-lg-none mobile-navbar">
        <Navbar.Toggle aria-controls="offcanvas-nav"/>
        <Button variant="danger" onClick={() => {
          dispatch(logout())
          history.push('/')
        }}>Log Out</Button>
      </Navbar>

      <div className={`d-lg-none offcanvas-collapse ${open ? 'open' : ''}`}>
        <Nav
          className="flex-column"
          activeKey={location.pathname}
          onSelect={(selectedKey) => {
            setOpen(false)
            history.push(selectedKey)
          }}
          fill
        >
          <Nav.Item>
            <Nav.Link eventKey="/"><FontAwesomeIcon icon={['far', 'house']} />Home</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="/subscriptions"><FontAwesomeIcon icon={['far', 'list-alt']} />Subscriptions</Nav.Link>
          </Nav.Item>
          {[3, 1].includes(user.role) ? (<Nav.Item>
            <Nav.Link eventKey="/reporting"><FontAwesomeIcon icon={['fas', 'chart-line']} />Reporting</Nav.Link>
          </Nav.Item>) : null}
          {[3].includes(user.role) ? (<Nav.Item>
            <Nav.Link eventKey="/admin-users"><FontAwesomeIcon icon={['fas', 'lock']} />Admin Users</Nav.Link>
          </Nav.Item>) : null}
          {[3, 1].includes(user.role) ? (<Nav.Item>
            <Nav.Link eventKey="/customers"><FontAwesomeIcon icon={['far', 'building']} />Customers</Nav.Link>
          </Nav.Item>) : null}
          <Nav.Item>
            <Nav.Link eventKey="/payments"><FontAwesomeIcon icon={['far', 'credit-card-front']} />Payments</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="/profile"><FontAwesomeIcon icon={['fas', 'user']} />Profile</Nav.Link>
          </Nav.Item>
        </Nav>
      </div>

      <Navbar className="navbar-white logo-navigation-container">
        <Nav
          className="logo-navigation"
          onSelect={(selectedKey) => {
            if (selectedKey === '/logout') {
              dispatch(logout())
              history.push('/')
            } else {
              history.push(selectedKey)
            }
          }}
        >
          <Nav.Item>
            <Nav.Link eventKey="/">
              <img
                src={require("../Resources/Images/u6.png")}
                alt="Main-Logo"
                className="site-logo"
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="login-container">
            <Nav.Link eventKey="/logout">
              <Button variant="danger" className="d-none d-lg-block">Log Out</Button>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar>
      <Navbar bg="dark" variant="dark" expand="lg" className="d-none d-lg-block">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            activeKey={location.pathname}
            onSelect={(selectedKey) => history.push(selectedKey)}
            fill
          >
            <Nav.Item>
              <Nav.Link eventKey="/subscriptions"><FontAwesomeIcon icon={['far', 'list-alt']} />Subscriptions</Nav.Link>
            </Nav.Item>
            {[3, 1].includes(user.role) ? (<Nav.Item>
              <Nav.Link eventKey="/reporting"><FontAwesomeIcon icon={['fas', 'chart-line']} />Reporting</Nav.Link>
            </Nav.Item>) : null}
            {[3].includes(user.role) ? (<Nav.Item>
              <Nav.Link eventKey="/admin-users"><FontAwesomeIcon icon={['fas', 'lock']} />Admin Users</Nav.Link>
            </Nav.Item>) : null}
            {[3, 1].includes(user.role) ? (<Nav.Item>
              <Nav.Link eventKey="/customers"><FontAwesomeIcon icon={['far', 'building']} />Customers</Nav.Link>
            </Nav.Item>) : null}
            <Nav.Item>
              <Nav.Link eventKey="/payments"><FontAwesomeIcon icon={['far', 'credit-card-front']} />Payments</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/profile"><FontAwesomeIcon icon={['fas', 'user']} />Profile</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar >
    </>
  )
}

export default connect()(Navigation)

