import React, { Component } from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {
  withRouter,
  Link
} from "react-router-dom";
import Input from 'components/input'
import { checkUserToken, activateUser } from 'actions/activationActions'
import Loader from 'components/loader'

class Register extends Component {
  constructor(props) {
    super(props)

    this.onInputChange = this.onInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      token: props.match.params.token,
      initPassword: '',
      confirmPassword: ''
    }
  }

  componentDidMount() {
    this.props.dispatch(checkUserToken(this.state.token));
  }

  handleSubmit(event) {
    const data = this.state;

    this.props.dispatch(activateUser(data))

    event.preventDefault();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activating && !this.props.activating && !this.props.activationError) {
      this.props.history.push('/')
    }
  }

  onInputChange(name, value) {
    this.setState({[name]: value})
  }

  render() {
    const {user, activating, activationError, fetchingToken, invalidToken} = this.props;
    const errors = activationError && activationError.errors ? activationError.errors : {}

    return (
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <div className="content-block">
              <h1 className="logged-out-header">{invalidToken ? 'Invalid Token' : user ? `Welcome, ${user.firstName}` : 'Welcome'}</h1>
              <img
                src={require("../Resources/Images/u6.png")}
                alt="Main-Logo"
                className="logged-out-logo"
              />
              {invalidToken ? (
                <div>Your token is either incorrect or has expired. You can receive a new token <Link to="/reset-password">here</Link>.</div>
              ) : (
                <Form onSubmit={this.handleSubmit}>
                  <p>Please set your password below to access the Web App.</p>
                  <Form.Group>
                    <Input attributeName="InitPassword" name="Password" type="password" data={this.state} errors={errors} onChange={this.onInputChange} />
                  </Form.Group>
                  <Form.Group>
                    <Input attributeName="ConfirmedPassword" type="password" errors={errors} placeholder="Confirm Password" name="Confirm" data={this.state} onChange={this.onInputChange} />
                  </Form.Group>
                  <Row className="align-items-center">
                    <Col lg="auto">
                      <Button variant="primary" type="submit" className={fetchingToken ? 'loading' : ''} disabled={activating || fetchingToken}>
                        Register
                      </Button>
                    </Col>
                    <Col className="text-center">
                      {fetchingToken ? (<Loader size="small"/>) : null}
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}


export default connect(state => ({
  user: state.activationReducer.user,
  fetchingToken: state.activationReducer.fetchingToken,
  activating: state.activationReducer.activating,
  activationError: state.activationReducer.activationError,
  invalidToken: state.activationReducer.invalidToken,
}))(withRouter(Register));
