import axios from 'axios';
import Cookies from 'js-cookie'

const jwt = Cookies.get('token');

axios.defaults.timeout = 30000
axios.defaults.baseURL = 'https://bds-portal-api.321test.com/api'

if (window.location.hostname === 'localhost') axios.defaults.baseURL = 'http://localhost:8181/api/';
if (window.location.hostname === 'portal.mobilebrochure.com') axios.defaults.baseURL = 'https://portal-api.mobilebrochure.com/api/';
if (jwt) axios.defaults.headers['Authorization'] = `Bearer ${jwt}`;

const settings = {
  adminEmail: 'noah@ucreate.us'
}

const userRoles = {
  '0': 'Company User',
  '2': 'Company Admin',
  '1': 'BDS Staff',
  '3': 'BDS Admin',
}

const recordStatus = {
  '0': 'Enabled',
  '1': 'Disabled'
}

const subscriptionInterval = {
  '0': 'Daily',
  '1': 'Weekly',
  '2': 'Monthly',
  '3': 'Yearly'
}

const subscriptionIntervalAlt = {
  '0': 'Day',
  '1': 'Week',
  '2': 'Month',
  '3': 'Year'
}

const subscriptionType = {
  '0': 'Primary',
  '1': 'Secondary',
  '2': 'Tertiary'
}

const paymentStatus = {
  '0': 'Succeeded',
  '1': 'Pending',
  '2': 'Failed'
}

const models = {
  'user': {plural: 'users', parent: 'company'},
  'subscription': {plural: 'subscriptions'},
  'locationSubscription': {plural: 'locationSubscriptions', parent: 'subscription'},
  'company': {plural: 'companies'},
  'location': {plural: 'locations', parent: 'company'},
  'payment': {plural: 'payments'},
  'paymentMethod': {plural: 'paymentMethods', parent: 'company'}
}

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'});

function enumsForSelect(enums, asc = false) {
  return {options: Object.entries(enums).sort((a, b) => asc ? a[1].localeCompare(b[1]) : b[1].localeCompare(a[1])).map(([key, value]) => {return {key, value}}), optionValue: o => parseInt(o.key), optionLabel: o => o.value};
}

export default models;

export {
  formatter,
  settings,
  userRoles,
  recordStatus,
  subscriptionInterval,
  subscriptionIntervalAlt,
  subscriptionType,
  paymentStatus,
  enumsForSelect
}