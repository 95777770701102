import ActionTypes from '../actionTypes/loginTypes';

const initialState = {
  loggingInUser: false,
  loginError: false,
  loggedInUser: {},
  checkingLogin: true
};

function loginUser(state = initialState, action) {
  switch(action.type) {
    case ActionTypes.LOGGING_IN: {
      return {...state, loggingInUser: true, loginError: false};
    }
    case ActionTypes.LOGGING_IN_COMPLETE: {
      return {...state, loggingInUser: false, loginError: false, loggedInUser: action.loggedInUser};
    }
    case ActionTypes.LOGGING_IN_ERROR: {
      return {...state, loginError: action.data, loggingInUser: false};
    }
    case ActionTypes.RESET_LOGGING_IN_ERRORS: {
      return {...state, loginError: false};
    }
    case ActionTypes.LOGIN_CHECK_COMPLETE: {
      return {...state, checkingLogin: false};
    }
    case ActionTypes.UPDATE_LOGGED_IN_USER: {
      const user = action.users.find(u => u.id === state.loggedInUser.id)
      return {...state, loggedInUser: user ? user : state.loggedInUser};
    }
    case ActionTypes.RESET_LOGIN_USER: {
      return initialState;
    }
    case ActionTypes.LOGOUT: {
      return {...state, loggedInUser: {}};
    }
    default:
      return state;
  }
}

export default loginUser;