import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Input from 'components/input';
import { updateUser } from 'actions/profileActions';
import { userRoles, enumsForSelect } from 'utils/config';
import { connect } from 'react-redux';

class ProfileUser extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = props.loggedInUser;
  }

  handleSubmit(event) {
    const data = this.state;

    this.props.dispatch(updateUser(data));

    event.preventDefault();
  }

  onChange(name, value) {
    this.setState({ [name]: value })
  }

  render() {
    const { updating, updateError } = this.props;
    const errors = updateError && updateError.errors ? updateError.errors : {}
    const userRolesOptions = enumsForSelect(userRoles);

    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <Form.Group>
            <Form.Row>
              <Input column={true} name="First Name" placeholder="First Name" col={true} data={this.state} errors={errors} onChange={this.onChange} />
              <Input column={true} name="Last Name" placeholder="Last Name" col={true} data={this.state} errors={errors} onChange={this.onChange} />
            </Form.Row>
          </Form.Group>
          <Form.Group>
            <Form.Row>
              <Input column={true} attributeName="UserName" col={true} disabled={true} name="Username" data={this.state} errors={errors} onChange={this.onChange} />
              <Input column={true} name="Phone" mask={"(###) ###-####"} col={true} data={this.state} errors={errors} onChange={this.onChange} />
            </Form.Row>
          </Form.Group>
          <Form.Group>
            <Form.Row>
              <Input column={true} name="Email" col={true} data={this.state} errors={errors} onChange={this.onChange} />
              <Input column={true} disabled={true} col={true} as="select" {...userRolesOptions} name="Role" data={this.state} errors={errors} onChange={this.onChange} />
            </Form.Row>
          </Form.Group>
          <Button variant="primary" type="submit" className={updating ? 'loading' : ''} disabled={updating}>
            Update Profile
        </Button>
        </Form>
      </div>
    )
  }
}


export default connect(state => ({
  loggedInUser: state.loginReducer.loggedInUser,
  updating: state.profileReducer.updating,
  updateError: state.profileReducer.updateError,
}))(ProfileUser);