import React, { Component } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import AddModal from 'screens/modals/add';
import EditModal from 'screens/modals/edit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux'
import actions from 'actions/modelActions'
import Loader from 'components/loader'
import PaginationComponent from 'components/pagination'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Cookies from 'js-cookie'
import helper from 'helpers/global'
import Input from 'components/input'
import { withRouter } from "react-router-dom";
import _ from 'lodash'
var refs;
var mobileRefs;

class ModelView extends Component {
  constructor(props) {
    super(props)

    this.setShow = this.setShow.bind(this)
    this.setEdit = this.setEdit.bind(this)
    this.setEditParent = this.setEditParent.bind(this)
    this.delete = this.delete.bind(this)
    this.toggleStatus = this.toggleStatus.bind(this)
    this.setPage = this.setPage.bind(this)
    this.setPerPage = this.setPerPage.bind(this)
    this.showCustomModal = this.showCustomModal.bind(this)
    this.handleCustomModalSubmit = this.handleCustomModalSubmit.bind(this)
    this.onCustomModalValueChange = this.onCustomModalValueChange.bind(this)
    this.renderField = this.renderField.bind(this)
    this.cardContent = this.cardContent.bind(this)

    this.state = {
      show: false,
      editItem: null,
      editParentItem: null,
      initialLoad: true,
      highlight: false,
      showCustomModal: false,
      customModalValues: {},
      mobileCardContent: (<></>)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.setState({ initialLoad: false });
      this.showCustomModal(false);

      if (this.props.highlightId && !this.state.highlight) {
        if (refs[this.props.highlightId].current) {
          refs[this.props.highlightId].current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }

        if (mobileRefs[this.props.highlightId].current) {
          mobileRefs[this.props.highlightId].current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }

        this.setState({ highlight: true }, () => {
          setTimeout(() => {
            this.setState({ highlight: false })
          }, 1500);
        });
      }
    }

    if (prevProps.params !== this.props.params) {
      const { dispatch, model, page, pageSize, parentId, params } = this.props;

      dispatch(actions[model].setPage(page, pageSize, true, parentId, params))
    }
  }

  setShow(show) {
    this.setState({ show })
    this.props.onModalToggle()
  }

  setEdit(editItem) {
    this.setState({ editItem })
    this.props.onModalToggle()
  }

  setEditParent(editParentItem) {
    this.setState({ editParentItem })
    this.props.onModalToggle()
  }

  showCustomModal(showCustomModal) {
    this.setState({ showCustomModal })
  }

  onCustomModalValueChange(name, value, objectName, objectValue) {
    var oldState = _.cloneDeep(this.state.customModalValues)

    var changedState;
    if (objectName) {
      oldState = _.merge(oldState, helper.deepen({ [objectName]: null }))
      changedState = helper.deepen({ [name]: value, [objectName]: objectValue })
    } else {
      changedState = helper.deepen({ [name]: value })
    }
    this.setState({ customModalValues: _.merge(oldState, changedState) })
  }

  handleCustomModalSubmit(event) {
    event.preventDefault();

    const { customModal, model, page, pageSize, parentId, dispatch } = this.props;
    const { customModalValues } = this.state;

    if (!customModal.allowSubmit(customModalValues)) return false;

    dispatch(actions[model][customModal.action](customModal.formData(customModalValues), page, pageSize, parentId));
  }

  toggleStatus(item) {
    const { dispatch, model, page, pageSize, parentId, params, toggleChange } = this.props;

    var data = _.omitBy({ ...item }, _.isObject);
    if (toggleChange) {
      data = toggleChange(data)
      dispatch(actions[model].update(data, page, pageSize, parentId, params))
    } else {
      dispatch(actions[model].toggleStatus(data, page, pageSize, parentId, params))
    }
  }

  delete(item) {
    const { dispatch, model, page, pageSize, parentId, params, label } = this.props;

    if (window.confirm(`Are you sure you want to permanently delete this ${label}`)) {
      dispatch(actions[model].delete(item, page, pageSize, parentId, params))
    }
  }

  componentDidMount() {
    const { dispatch, model, page, parentId, params } = this.props;
    const pageSize = Cookies.get(`${model}PageSize`);

    dispatch(actions[model].setPage(page, pageSize, true, parentId, params))
  }

  setPage(page) {
    const { dispatch, model, pageSize, parentId, params } = this.props;

    dispatch(actions[model].setPage(page, pageSize, true, parentId, params))
  }

  setPerPage(pageSize) {
    const { dispatch, model, parentId, params } = this.props;
    Cookies.set(`${model}PageSize`, pageSize, { expires: 365, path: '/' });

    dispatch(actions[model].setPage(1, pageSize, true, parentId, params))
  }

  renderField(field, column = false) {
    return (
      <Input key={field.name} {...field} column={column} data={this.state.customModalValues} errors={{}} onChange={this.onCustomModalValueChange} />
    );
  }

  cardContent(itemHeaders, arr, item) {
    var mobileContentHeader = [];
    var mobileBody = [];
    arr.forEach((cell, index) => {
      if (index === 0 || itemHeaders[index].toLowerCase() === "status") {
        mobileContentHeader.push(cell(item))
      } else if (itemHeaders[index].toLowerCase() !== "status") {
        mobileBody.push((<p key={index}><span>{itemHeaders[index]}: </span>{cell(item)}</p>))
      }
      return 1;
    })
    return (
      <>
        <Card.Header>
          <h5>
            {mobileContentHeader[0]}
          </h5>
          <span>{mobileContentHeader[1]}</span>
        </Card.Header>
        <Card.Body>
          {mobileBody.map(i => (i))}
        </Card.Body>
      </>
    )
  };

  render() {
    const { show, editItem, editParentItem, highlight, showCustomModal } = this.state
    const { editSettings, filter, history, location, manage, disableEdit, parentId, model, pluralModel, editableParent, loading, results, pageCount, pageSize, rowCount, page, highlightId, header, copy, headers, cells, fields, icon, title, label, groupBy, toggleStatusDisabled, containerClass, customModal, newLabel, defaultItem, actionButtons, toggleText, disableDelete } = this.props
    refs = results.reduce((acc, value) => {
      acc[value.id] = React.createRef();
      return acc;
    }, {});

    mobileRefs = results.reduce((acc, value) => {
      acc[value.id] = React.createRef();
      return acc;
    }, {});

    const groupedResults = groupBy ? _.groupBy(results, groupBy) : { '': results };
    const showDelete = history.location.search === '?delete=true' || this.props.showDelete;

    return (
      <div className={`${model}-screen table-screen`}>
        {title ? <h1 className="page-header"><FontAwesomeIcon icon={icon} />{title}</h1> : null}
        <div className={containerClass}>
          {header || editableParent || fields || (customModal && customModal.button) ? (<Row className="mb-4">
            {header ? <Col>
              <h2>{header ? header : `${label}s List`}</h2>
            </Col> : null}
            <Col lg="6" className={header ? 'text-right model-actions' : 'model-actions'}>
              {editableParent ? (
                <Button variant="primary" className="edit-button mr-2" onClick={() => this.setEditParent(editableParent.item)} disabled={loading}>
                  <FontAwesomeIcon icon={['fal', 'pencil']} /> Edit {editableParent.label}
                </Button>
              ) : null}
              {fields ? (<Button variant="success" className="add-button" onClick={() => this.setShow({})} disabled={loading}>
                <FontAwesomeIcon icon={['fal', 'plus']} /> {newLabel ? newLabel : `Add New ${label}`}
              </Button>) : null}
              {customModal && customModal.button ? (
                <Button variant="success" className="ml-2" onClick={this.showCustomModal} disabled={loading}>
                  <FontAwesomeIcon icon={customModal.button.icon} /> {customModal.button.label}
                </Button>
              ) : null}
            </Col>
          </Row>) : null}

          {copy ? <p>{copy}</p> : null}

          {editableParent ? (<EditModal pluralModel={editableParent.pluralModel} label={editableParent.label} fields={editableParent.fields} model={editableParent.model} handleClose={() => this.setEditParent(null)} item={editParentItem} />) : null}

          <div>
            <PaginationComponent filter={filter} loading={loading} pageCount={pageCount} currentPage={page} setPage={this.setPage} setPerPage={this.setPerPage} perPage={pageSize} rowCount={rowCount} />
            {loading && results.length === 0 ? (
              <Loader size="large"><h2>Loading</h2></Loader>
            ) : (
                results.length > 0 ? (
                  <>
                    <div className="d-none d-lg-block">
                      <Table striped bordered responsive>
                        {Object.entries(groupedResults).map(([group, rows]) => (
                          <React.Fragment key={group}>
                            <thead>
                              <tr>
                                {headers.map((header, index) => index === 0 && group ? <th key={header}>{group} {header}</th> : <th key={header}>{header}</th>)}
                                {actionButtons === false ? null : <th>Actions</th>}
                              </tr>
                            </thead>
                            <tbody>
                              {rows.map(item => (
                                <tr ref={refs[item.id]} key={item.id} className={highlight && highlightId === item.id ? 'highlight' : null}>
                                  {cells.map((cell, index) => <td key={index}>{cell(item)}</td>)}
                                  {actionButtons === false ? null : (<td>
                                    <div className="actions-column">
                                      {actionButtons ? actionButtons(item, this.props.dispatch) :
                                        <div>
                                          {manage ? (<Button size="sm" variant="secondary" disabled={loading} onClick={() => { history.push(`${location.pathname}/${item.id}`) }}>
                                            <FontAwesomeIcon size="sm" icon={['fal', 'cog']} /> Manage
                                    </Button>) : null}
                                          {disableEdit ? null : (<Button size="sm" variant="primary" onClick={() => this.setEdit(item)} disabled={loading}>
                                            <FontAwesomeIcon size="sm" icon={['fal', 'pencil']} /> {editSettings(item).buttonLabel ? editSettings(item).buttonLabel : 'Edit'}
                                    </Button>)}
                                          {showDelete ? null : toggleStatusDisabled && toggleStatusDisabled(item) ?
                                            <Button size="sm" variant="secondary" disabled={true}>
                                              <FontAwesomeIcon size="sm" icon={['fal', 'ban']} /> Disable
                                      </Button>
                                            : item.status === 0 ? (
                                              <Button size="sm" variant="danger" disabled={loading} onClick={() => this.toggleStatus(item)}>
                                                <FontAwesomeIcon size="sm" icon={['fal', 'times']} /> {toggleText(item)}
                                              </Button>
                                            ) : (
                                                <Button size="sm" variant="success" disabled={loading} onClick={() => this.toggleStatus(item)}>
                                                  <FontAwesomeIcon size="sm" icon={['fal', 'plus']} /> {toggleText(item)}
                                                </Button>
                                              )}
                                            {showDelete ? <Button size="sm" variant="danger" disabled={loading || disableDelete(item)} onClick={() => this.delete(item)}>
                                              <FontAwesomeIcon size="sm" icon={disableDelete(item) ?  ['fal', 'ban']: ['fas', 'trash']} /> Permanently Delete
                                            </Button> : null}
                                        </div>
                                      }
                                    </div>
                                  </td>)}
                                </tr>
                              ))}
                            </tbody>
                          </React.Fragment>
                        ))}
                      </Table>
                    </div>
                    <div className="d-block d-lg-none mobile-record">
                      {Object.entries(groupedResults).map(([group, rows]) => (
                        <React.Fragment key={group}>
                          {rows.map(item => (
                            <Card
                              key={item.id}
                              ref={mobileRefs[item.id]}
                              className={highlight && highlightId === item.id ? 'highlight mb-3' : 'mb-3'}
                            >
                              {this.cardContent(headers, cells, item)}
                              {actionButtons === false ? null : (
                                <div className="actions-column">
                                  {actionButtons ? actionButtons(item) :
                                    <Card.Footer>
                                      {manage ? (<Button size="sm" variant="secondary" disabled={loading} onClick={() => { history.push(`${location.pathname}/${item.id}`) }}>
                                        <FontAwesomeIcon size="sm" icon={['fal', 'cog']} /> Manage
                                      </Button>) : null}
                                        {disableEdit ? null : (<Button size="sm" variant="primary" onClick={() => this.setEdit(item)} disabled={loading}>
                                          <FontAwesomeIcon size="sm" icon={['fal', 'pencil']} /> Edit
                                      </Button>)}
                                      {showDelete ? null : toggleStatusDisabled && toggleStatusDisabled(item) ?
                                        <Button size="sm" variant="secondary" disabled={true}>
                                          <FontAwesomeIcon size="sm" icon={['fal', 'ban']} /> Disable
                                        </Button>
                                      : item.status === 0 ? (
                                        <Button size="sm" variant="danger" disabled={loading} onClick={() => this.toggleStatus(item)}>
                                          <FontAwesomeIcon size="sm" icon={['fal', 'times']} /> {toggleText(item)}
                                        </Button>
                                      ) : (
                                        <Button size="sm" variant="success" disabled={loading} onClick={() => this.toggleStatus(item)}>
                                          <FontAwesomeIcon size="sm" icon={['fal', 'plus']} /> {toggleText(item)}
                                        </Button>
                                      )}
                                      {showDelete ? <Button size="sm" variant="danger" disabled={loading || disableDelete(item)} onClick={() => this.delete(item)}>
                                        <FontAwesomeIcon size="sm" icon={disableDelete(item) ?  ['fal', 'ban']: ['fas', 'trash']} /> Permanently Delete
                                      </Button> : null}
                                    </Card.Footer>
                                  }
                                </div>)}
                            </Card>
                          ))}
                        </React.Fragment>
                      ))}
                    </div>
                  </>
                ) : (fields ? <div>No {label}s yet. Click the "{newLabel ? newLabel : `Add New ${label}`}" button above to create a new one.</div> : <div>No {label}s.</div>)
              )}
            <PaginationComponent bottom loading={loading} pageCount={pageCount} currentPage={page} setPage={this.setPage} setPerPage={this.setPerPage} perPage={pageSize} rowCount={rowCount} />
          </div>
        </div>

        {fields ? <AddModal parentId={parentId} pluralModel={pluralModel} label={label} fields={fields} model={model} handleClose={() => this.setShow(false)} item={show ? defaultItem : false} /> : null}
        {disableEdit ? null : (<EditModal editSettings={editSettings} parentId={parentId} pluralModel={pluralModel} label={label} fields={fields} model={model} handleClose={() => this.setEdit(null)} item={editItem} />)}
        {customModal ? (
          <Modal show={showCustomModal} onHide={() => this.showCustomModal(false)} animation={false}>
            <Form onSubmit={this.handleCustomModalSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{customModal.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {customModal.fields.map((field, index) => Array.isArray(field) ? (
                  <Form.Group key={index}>
                    <Form.Row>
                      {field.map(subField => this.renderField(subField, true))}
                    </Form.Row>
                  </Form.Group>
                ) : (
                    <Form.Group key={index}>
                      {this.renderField(field)}
                    </Form.Group>
                  ))}
              </Modal.Body>
              <Modal.Footer className="justify-content-between">
                <Button variant="secondary" onClick={() => this.showCustomModal(false)}>
                  Cancel
                </Button>
                <Button variant="primary" className={loading ? 'loading' : ''} type="submit" onClick={this.handleCustomModalSubmit} disabled={loading || _.isEmpty(_.pickBy(this.state.customModalValues))}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        ) : null}
      </div>
    )
  }
};

ModelView.defaultProps = {
  containerClass: 'content-block',
  disableDelete: () => false,
  onModalToggle: () => { },
  defaultItem: {
    status: '0'
  },
  editSettings: () => {return {}},
  params: '',
  toggleText: item => item.status === 0 ? 'Disable' : 'Enable'
}

export default connect((state, props) => ({
  ...state[`${props.pluralModel}Reducer`]
}))(withRouter(ModelView));
