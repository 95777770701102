import actionTypes from 'actionTypes/globalTypes';
import handleAxiosError from 'helpers/axiosHelpers'
import axios from 'axios';

export function showConfirmation(message) {
  return async dispatch => {
    dispatch({
      type: actionTypes.SET_CONFIRMATION_MESSAGE,
      message
    });
  }
}

export function hideConfirmation() {
  return async dispatch => {
    dispatch({
      type: actionTypes.SET_CONFIRMATION_MESSAGE,
      message: ''
    });
  }
}

export function getBrochureUrl(location) {
  return async dispatch => {
    dispatch({
      type: actionTypes.FETCHING_BROCHURE_URL
    });

    try {
      const response = await axios.get(`https://www.mobilebrochure.com/${location.destination.siteUrl}/wp-json/wc/v2/products/${location.brochureId}?consumer_key=${location.destination.siteKey}&consumer_secret=${location.destination.siteSecret}`)
      const url = new URL(response.data.permalink);

      dispatch({
        type: actionTypes.BROCHURE_URL_COMPLETE,
        data: url.pathname
      });
    } catch (e) {
      handleAxiosError(e, dispatch, actionTypes.BROCHURE_URL_ERROR)
    }
  }
}