import React, { Component } from 'react'
import { connect } from 'react-redux'
import { login } from 'actions/loginActions'
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Input from 'components/input';
import Button from 'react-bootstrap/Button';
import {
  withRouter,
  Link
} from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      identity: '',
      password: '' 
    }
  }

  handleSubmit(event) {
    const data = this.state;
    
    this.props.dispatch(login(data));
    
    event.preventDefault();
  }

  onInputChange(name, value) {
    this.setState({ [name]: value })
  }

  render() {
    const { loginError, loggingInUser } = this.props
    const errors = loginError && loginError.errors ? loginError.errors : {}

    return (
      <div className="centered-content-container">
        <Container>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <div className="content-block">
                <h1 className="logged-out-header">Web App Login</h1>
                <img
                  src={require("../Resources/Images/u6.png")}
                  alt="Main-Logo"
                  className="logged-out-logo"
                />
                <Form onSubmit={this.handleSubmit}>
                  <p className="text-danger">{(loginError) ? (loginError.title) : null}</p>
                  <Form.Group>
                    <Input attributeName="Identity" name="Username" placeholder="Username or Email" type="text" data={this.state} errors={errors} onChange={this.onInputChange} />
                  </Form.Group>
                  <Form.Group>
                    <Input attributeName="Password" name="Password" type="Password" data={this.state} errors={errors} onChange={this.onInputChange} />
                  </Form.Group>
                  
                  <Button variant="primary" type="submit" className={loggingInUser ? 'loading' : ''} disabled={loggingInUser}>
                    Login
                  </Button>

                  <Link className="float-right" to="/reset-password">Lost your Password?</Link>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default connect(state => ({
  loggedInUser: state.loginReducer.loggedInUser,
  loggingInUser: state.loginReducer.loggingInUser,
  loginError: state.loginReducer.loginError,
}))(withRouter(Login));