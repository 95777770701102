import actionTypes from '../actionTypes/activationTypes';
import globalTypes from '../actionTypes/globalTypes';
import handleAxiosError from 'helpers/axiosHelpers'
import axios from 'axios';

export function checkUserToken(token) {
  return async dispatch => {
    dispatch({
      type: actionTypes.FETCHING_TOKEN
    });

    try {
      const response = await axios.get(`/users/token/${token}`);

      dispatch({
        type: actionTypes.FETCHING_TOKEN_COMPLETE,
        user: response.data
      });

    } catch (e) {
      handleAxiosError(e, dispatch, actionTypes.TOKEN_ERROR)
    }
  };
}

export function activateUser(data) {
  return async dispatch => {
    dispatch({
      type: actionTypes.ACTIVATION_LOADING
    });

    try {
      const response = await axios.post(`/users/SetPassword`, data);

      dispatch({
        type: actionTypes.ACTIVATION_COMPLETE,
        user: response.data
      });

      dispatch({
        type: globalTypes.SET_CONFIRMATION_MESSAGE,
        message: `Password set`
      });
    } catch (e) {
      handleAxiosError(e, dispatch, actionTypes.ACTIVATION_ERROR)
    }
  };
}

export function resetActivationErrors() {
  return async dispatch => {
    dispatch({
      type: actionTypes.RESET_ACTIVATION_ERRORS
    });
  };
}

export function requestPasswordReset(data) {
  return async dispatch => {
    dispatch({
      type: actionTypes.REQUESTING_RESET
    });

    try {
      await axios.post(`/users/PasswordReset?data=${data.data}`);

      dispatch({
        type: actionTypes.REQUESTING_RESET_COMPLETE
      });

      dispatch({
        type: globalTypes.SET_CONFIRMATION_MESSAGE,
        message: `Email sent`
      });
    } catch(e) {
      handleAxiosError(e, dispatch, actionTypes.REQUESTING_RESET_ERROR);
    }
  }
}
