import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Input from 'components/input';
import { connect } from 'react-redux';
import { updatePassword } from '../actions/profileActions';
import { withRouter } from "react-router-dom";
import { logout } from 'actions/loginActions';

class ProfilePassword extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = {
      currentPassword: "",
      initPassword: "",
      confirmPassword: ""
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updatingPassword && !this.props.updatingPassword && !this.props.updatePasswordError) {
      this.props.dispatch(logout());
    }
  }


  handleSubmit(event) {
    event.preventDefault();
    const data = this.state;

    this.props.dispatch(updatePassword(data));
  }

  onChange(name, value) {
    this.setState({ [name]: value })
  }

  render() {
    const { updatingPassword, updatePasswordError } = this.props
    const errors = updatePasswordError && updatePasswordError.errors ? updatePasswordError.errors : {}

    return (
      <Row>
        <Col lg={6}>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group>
              <Input attributeName="CurrentPassword" name="Current" placeholder="Current Password" type="password" data={this.state} errors={errors} onChange={this.onChange} />
            </Form.Group>
            <Form.Group>
              <Input attributeName="InitPassword" name="New" placeholder="New Password" type="password" data={this.state} errors={errors} onChange={this.onChange} />
            </Form.Group>
            <Form.Group>
              <Input attributeName="ConfirmPassword" name="Confirm" placeholder="Confirm Password" type="password" data={this.state} errors={errors} onChange={this.onChange} />
            </Form.Group>
            <Button variant="primary" type="submit" className={updatingPassword ? 'loading' : ''} disabled={updatingPassword}>
              Change Password
          </Button>
          </Form>
        </Col>
      </Row>
    )
  }
}

export default connect(state => ({
  updatingPassword: state.profileReducer.updatingPassword,
  updatePasswordError: state.profileReducer.updatePasswordError
}))(withRouter(ProfilePassword))