import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { getReportsAccess } from 'actions/reportingActions'
import { GoogleDataChart } from 'components/chart'
import { GoogleProvider } from 'react-analytics-widget'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'components/loader'
import { getBrochureUrl } from 'actions/globalActions'
import Input from 'components/input'

class Reporting extends Component {
  constructor(props) {
    super(props)

    this.setLocation = this.setLocation.bind(this)

    this.state = {
      location: null,
      locationId: null
    }
  }

  componentDidMount() {
    this.props.dispatch(getReportsAccess())
  }

  setLocation(location) {
    this.props.dispatch(getBrochureUrl(location))
    this.setState({location, locationId: location ? location.id : null})
  }

  render() {
    const { location } = this.state
    const { brochureUrl } = this.props

    var query = {
      'ids': 'ga:85804350',
      'start-date': '30daysAgo',
      'end-date': 'yesterday',
      'dimensions': 'ga:date',
      'metrics': 'ga:pageviews, ga:uniquePageviews',
    };

    if (location && brochureUrl) {
      query['filters'] = `ga:pagePathLevel2=~^/brochure/;ga:pagePath=@${brochureUrl}`
    }

    const lineConfig = {
      reportType: "ga",
      query,
      chart: {
        type: "LINE",
        options: {
          width: '100%'
        }
      }
    }
     
    const tableConfig = {
      reportType: "ga",
      query,
      chart: {
        type: "TABLE",
        options: {
          width: '100%'
        }
      }
    }

    const views = {
      query: {
        ids: "ga:134691379"
      }
    }

    const {accessToken, fetchingReports, reportsError, fetchingBrochureUrl, brochureUrlError} = this.props;
    const loading = fetchingReports || fetchingBrochureUrl || (location && brochureUrlError);

    return (
      <div>
        <h1 className="page-header"><FontAwesomeIcon icon={['fas', 'chart-line']} /> Reporting</h1>
        
        <div className="content-block">
          {fetchingReports ? (<Loader size="large"><h2>Loading</h2></Loader>) : 
            reportsError ? (<div>There was an error fetching the reports.</div>) : (
              <div>
                <Input clearable={true} name="Location" attributeName="LocationId" options="/locations/all" optionValue={u => u.id} optionLabel={u => `${u.company.name} - ${u.name}`} as="select" data={this.state} errors={{}} onSelectChange={this.setLocation} disabled={fetchingBrochureUrl} />
                <GoogleProvider accessToken={accessToken}>
                  <GoogleDataChart views={views} config={lineConfig} loading={loading} loadingComponent={location && brochureUrlError ? <div className="m-3">There was an error fetching the analytics for the selected location.</div> : <Loader size="large"><h2>Loading</h2></Loader>} />
                  <GoogleDataChart views={views} config={tableConfig} loading={loading} loadingComponent={null} />
                </GoogleProvider>
              </div>
            )
          }
        </div>
      </div>
    )
  }
};

export default connect(state => ({
  accessToken: state.reportingReducer.accessToken,
  fetchingReports: state.reportingReducer.fetchingReports,
  reportsError: state.reportingReducer.reportsError,
  brochureUrlError: state.globalReducer.brochureUrlError,
  fetchingBrochureUrl: state.globalReducer.fetchingBrochureUrl,
  brochureUrl: state.globalReducer.brochureUrl,
}))(withRouter(Reporting));
