import _ from 'lodash'

function deepen(o) {
  var oo = {}, t, parts, part;
  for (var k in o) {
    t = oo;
    parts = k.split('.');
    var key = parts.pop();
    while (parts.length) {
      part = parts.shift();
      t = t[part] = t[part] || {};
    }
    t[key] = o[k]
  }
  return oo;
}

function pickKeys(object, keys) {
  const keyArray = Object.keys(keys);
  keyArray.push('id')
  var newObject = _.pick(object, keyArray)
  newObject = _.merge(keys, newObject)

  Object.entries(newObject).forEach(([key, value]) => newObject[key] = _.isObject(value) ? pickKeys(value, keys[key]) : value)

  return newObject;
}

function debounce(func){
  var timer;
  return function(event){
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, 250, event);
  };
}

export default { deepen, pickKeys, debounce }