import React, { Component } from 'react';
import ModalForm from 'components/modalForm'

class EditModal extends Component {
  render() {
    const {item, model, pluralModel, fields, label, handleClose, parentId, editSettings} = this.props;

    return (
      <ModalForm passableFields={fields} pluralModel={pluralModel} model={model} label={editSettings(item).modalLabel ? editSettings(item).modalLabel : `Edit ${label}`} fields={editSettings(item).fields ? editSettings(item).fields : fields} handleClose={handleClose} parentId={parentId} item={item} submit="update" changeMessage="In order to save, at least one field must be changed." />
    )
  }
}

EditModal.defaultProps = {
  editSettings: () => {return {}},
}

export default EditModal;
