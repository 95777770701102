import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux'
import ModelForm from 'components/modelForm'
import _ from 'lodash'
import actions from 'actions/modelActions'

class ModalForm extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading && !this.props.error) {
      this.props.handleClose();
    }

    if (!_.isEqual(prevProps.item, this.props.item)) {
      const {model, dispatch} = this.props;
      dispatch(actions[model].resetErrors());
      this.setState({...this.props.item});
    }
  }

  render() {
    const {item, handleClose, fields, label, changeMessage, model, pluralModel, submit, parentId, params, passableFields} = this.props;

    return (
      <Modal show={item ? true : false} onHide={handleClose} size="lg" animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>{label}</Modal.Title>
        </Modal.Header>
        <ModelForm 
          item={this.state}
          passableFields={passableFields}
          fields={fields}
          changeMessage={changeMessage}
          footerClassName="modal-footer justify-content-between"
          bodyClassName="modal-body"
          model={model}
          pluralModel={pluralModel}
          submit={submit}
          parentId={parentId}
          label={label}
          params={params}
          footerButtons={
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          }
        />
      </Modal>
    )
  }
}

export default connect((state, props) => ({
  ...state[`${props.pluralModel}Reducer`]
}))(ModalForm);
