import React, { Component } from 'react'
import _ from 'lodash'
import { formatter } from 'utils/config';
import { connect } from 'react-redux'
import Input from 'components/input'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'components/loader'
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import actions from 'actions/modelActions'

class Payments extends Component {
  constructor(props) {
    super(props)

    this.setParams = this.setParams.bind(this)
    this.cardContent = this.cardContent.bind(this)
    this.loadMore = this.loadMore.bind(this)

    var today = new Date();

    this.state = {
      params: `month=${today.getMonth() + 1}&year=${today.getFullYear().toString()}`,
      showLoading: true
    }
  }

  componentDidMount() {
    const { dispatch, last } = this.props;
    const { params, showLoading } = this.state;

    dispatch(actions.payment.setPage(1, 10, true, null, `${params}&startingAfter=${showLoading ? '' : last}`))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.setState({ showLoading: false });
    }
  }

  loadMore() {
    const { dispatch, last } = this.props;
    const { params } = this.state;

    dispatch(actions.payment.setPage(1, 10, true, null, `${params}&startingAfter=${last}`, true))
  }

  setParams(name, params) {
    const { dispatch, last } = this.props;

    this.setState({params, showLoading: true}, () => {
      dispatch(actions.payment.setPage(1, 10, true, null, `${params}&startingAfter=${this.state.showLoading ? '' : last}`))
    })
  }

  cardContent(itemHeaders, arr, item) {
    var mobileContentHeader = [];
    var mobileBody = [];
    arr.forEach((cell, index) => {
      if (index === 0 || itemHeaders[index].toLowerCase() === "status") {
        mobileContentHeader.push(cell(item))
      } else if (itemHeaders[index].toLowerCase() !== "status") {
        mobileBody.push((<p key={index}><span>{itemHeaders[index]}: </span>{cell(item)}</p>))
      }
      return 1;
    })
    return (
      <>
        <Card.Header>
          <h5>
            {mobileContentHeader[0]}
          </h5>
          <span>{mobileContentHeader[1]}</span>
        </Card.Header>
        <Card.Body>
          {mobileBody.map(i => (i))}
        </Card.Body>
      </>
    )
  }

  render() {
    var headers, cells;
    const { showLoading } = this.state;
    const { loading, results, hasMore } = this.props

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const now = new Date();

    if ([3, 1].includes(this.props.loggedInUser.role)) {
      headers = ['Location Name', 'Customer Name', 'Payment Date', 'Payment Status', 'Subscription Plan', 'Payment Reference Id', 'Payment Total']
      cells = [
        (item) => item.locationSubscription.location.name,
        (item) => item.locationSubscription.location.company.name,
        (item) => format(new Date(item.paymentDate), 'MMMM d, yyyy'),
        (item) => <div className="payment-status-container"><div className={`status-circle bg-${item.paymentStatus}`}></div>{_.startCase(item.paymentStatus)}</div>,
        (item) => item.locationSubscription.subscription.name,
        (item) => item.id,
        (item) => formatter.format(item.paymentTotal),
      ]
    } else {
      headers = ['Location Name', 'Payment Date', 'Payment Status', 'Subscription Plan', 'Payment Reference Id', 'Payment Total']
      cells = [
        (item) => item.locationSubscription.location.name,
        (item) => format(new Date(item.paymentDate), 'MMMM d, yyyy'),
        (item) => <div className="payment-status-container"><div className={`status-circle bg-${item.paymentStatus}`}></div>{_.startCase(item.paymentStatus)}</div>,
        (item) => item.locationSubscription.subscription.name,
        (item) => item.id,
        (item) => formatter.format(item.paymentTotal),
      ]
    }

    return (
      <div className="payments-screen table-screen">
        <h1 className="page-header"><FontAwesomeIcon icon={['far', 'credit-card-front']} />Payments</h1>

        <div className="content-block">
          <Input
            name="Month"
            attributeName="params"
            label={false}
            options={Array(36).fill(1).map((v, i) => {
              var future = new Date(now.getFullYear(), now.getMonth() - i, 1);
              var month = future.getMonth() + 1;
              var monthName = monthNames[future.getMonth()];
              var year = future.getFullYear();

              return {month, monthName, year}
            })} 
            className="mb-3"
            disabled={loading}
            optionValue={u => `month=${u.month}&year=${u.year}`} 
            optionLabel={u => `${u.monthName} ${u.year}`} 
            as="select" 
            data={this.state} 
            errors={{}} 
            onChange={this.setParams} 
          />

          {loading && showLoading ? (
            <Loader size="large"><h2>Loading</h2></Loader>
          ) : (
            <>
              {results.length > 0 ? (
                <>
                  <div className="d-none d-lg-block">
                    <Table striped bordered responsive>
                      <thead>
                        <tr>
                          {headers.map((header, index) => <th key={header}>{header}</th>)}
                        </tr>
                      </thead>
                      <tbody>
                        {results.map(item => (
                          <tr key={item.id}>
                            {cells.map((cell, index) => <td key={index}>{cell(item)}</td>)}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <div className="d-block d-lg-none mobile-record">
                    {results.map(item => (
                      <Card
                        key={item.id}
                        className="mb-3"
                      >
                        {this.cardContent(headers, cells, item)}
                      </Card>
                    ))}
                  </div>
                  {hasMore ? <div className="text-center">
                    <Button disabled={loading} className={loading ? 'loading' : ''} variant="secondary" onClick={this.loadMore}>Load More</Button>
                  </div> : null}
                </>
              ) : <div>No payments for the selected month.</div>}
            </>
          )}
        </div>
      </div>
    )
  }
};

export default connect(state => ({
  loggedInUser: state.loginReducer.loggedInUser,
  ...state['paymentsReducer']
}))(Payments);
