import actionTypes from '../actionTypes/profileTypes';
import globalTypes from '../actionTypes/globalTypes';
import loginActionTypes from '../actionTypes/loginTypes';
import handleAxiosError from 'helpers/axiosHelpers'
import axios from 'axios';

export function updateUser(data) {
  return async dispatch => {
    dispatch({
      type: actionTypes.UPDATING_PROFILE
    });

    try {
      const response = await axios.put('/users/profile/', data);

      dispatch({
        type: actionTypes.UPDATING_PROFILE_COMPLETE,
      });

      dispatch({
        type: loginActionTypes.LOGGING_IN_COMPLETE,
        loggedInUser: response.data
      });

      dispatch({
        type: globalTypes.SET_CONFIRMATION_MESSAGE,
        message: `Updated profile`
      })
    } catch (e) {
      handleAxiosError(e, dispatch, actionTypes.UPDATING_PROFILE_ERROR);
    }
  }
}

export function updatePassword(data) {
  return async dispatch => {
    dispatch({
      type: actionTypes.UPDATING_PASSWORD
    });

    try {
      await axios.put('Users/Profile/SetPassword', data);

      dispatch({
        type : actionTypes.UPDATING_PASSWORD_COMPLETE,
      });

      dispatch({
        type: globalTypes.SET_CONFIRMATION_MESSAGE,
        message: `Updated password`
      })
    } catch (e) {
      handleAxiosError(e, dispatch, actionTypes.UPDATING_PASSWORD_ERROR);
    }
  }
} 

export function resetErrors() {
  return async dispatch => {
    dispatch({
      type: actionTypes.RESET_ERRORS
    });
  }
} 