import ActionTypes from 'actionTypes/modelTypes';
import models from 'utils/config'

const modelReducers = {}

Object.entries(models).forEach(([model, options]) => {
  const plural = options.plural;
  
  const initialState = {
    loading: false,
    results: [],
    error: false,
    pageCount: 0,
    pageSize : 10,
    rowCount: 0,
    highlightId: null,
    hasMore: null,
    last: '',
    page: 1
  };

  modelReducers[`${plural}Reducer`] = function(state = initialState, action) {
    switch (action.type) {
      case ActionTypes[model].FETCHING: {
        return { ...state, error: false, loading: true };
      }
      case ActionTypes[model].FETCHING_COMPLETE: {
        const {results, pageCount, pageSize, rowCount, page, highlightId, append, hasMore, last} = action;
        return { ...state, loading: false, error: false, results: append ? state.results.concat(results) : results, pageCount, pageSize, rowCount, page, highlightId, hasMore, last };
      }
      case ActionTypes[model].FETCHING_ERROR: {
        return { ...state, error: action.data, loading: false };
      }
      case ActionTypes[model].RESET_ERRORS: {
        return { ...state, error: false };
      }
      case ActionTypes[model].SET_PAGE: {
        return { ...state, page: action.page };
      }
      case ActionTypes[model].SET_PAGE_SIZE: {
        return { ...state, pageSize: action.pageSize };
      }
      case ActionTypes[model].RESET: {
        return initialState;
      }
      default:
        return state;
    }
  } 
})

export default modelReducers;
