import ActionTypes from '../actionTypes/reportingTypes';

const initialState = {
  fetchingReports: true,
  reportsError: false,
  accessToken: null
};

function reportingReducer(state = initialState, action) {
  switch(action.type) {
    case ActionTypes.FETCHING_REPORTS: {
      return {...state, fetchingReports: true, reportsError: false};
    }
    case ActionTypes.FETCHING_REPORTS_COMPLETE: {
      return {...state, fetchingReports: false, reportsError: false, accessToken: action.accessToken};
    }
    case ActionTypes.REPORTS_ERROR: {
      return {...state, reportsError: action.data, fetchingReports: false};
    }
    case ActionTypes.RESET_REPORTS: {
      return initialState;
    }
    default:
      return state;
  }
}

export default reportingReducer;