import React, { Component } from 'react';
import ModalForm from 'components/modalForm'

class AddModal extends Component {
  render() {
    const {item, model, pluralModel, fields, label, handleClose, parentId} = this.props;

    return (
      <ModalForm pluralModel={pluralModel} model={model} label={`Add ${label}`} fields={fields} handleClose={handleClose} parentId={parentId} item={item} submit="add" changeMessage="Please enter field values." />
    )
  }
}

export default AddModal
