import React, { Component } from 'react'
import ModelView from 'components/modelView'
import ModelForm from 'components/modelForm'
import { recordStatus, userRoles, enumsForSelect, formatter } from 'utils/config';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import actions from 'actions/modelActions'
import LoadingScreen from 'screens/loading';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { Html5Entities } from 'html-entities';
import { format } from 'date-fns'
import { subscriptionInterval, subscriptionIntervalAlt, subscriptionType } from 'utils/config';
const htmlEntities = new Html5Entities();

class CustomerManagement extends Component {
  showUserModal(showUserModal = true) {
    this.setState({ showUserModal })
  }

  showLocationModal(showLocationModal = true) {
    this.setState({ showLocationModal })
  }

  componentDidMount() {
    if (this.props.companies.length === 0) {
      this.props.dispatch(actions.company.fetch(1, 10, this.props.match.params.id))
    }
  }

  render() {
    const { companies } = this.props;
    const company = companies.find(c => c.id === parseInt(this.props.match.params.id))

    if (!company) return (
      <div className="mt-5">
        <LoadingScreen />
      </div>
    );

    return (
      <div>
        <h1 className="page-header">
          <Row className="mb-0 align-items-center">
            <Col>Edit {company.name}</Col>
            <Col lg="auto" className="page-header-small">
              <FontAwesomeIcon icon={['far', 'building']} />Customer Management
            </Col>
          </Row>
        </h1>
        <Tabs defaultActiveKey="edit">
          <Tab eventKey="edit" title="Company Information">
            <ModelForm
              item={company}
              label="Company"
              fields={[
                [
                  { name: 'Name', placeholder: 'Company Name' },
                  { name: 'Email' }
                ],
                [
                  { name: 'Phone', mask: "(###) ###-####" },
                  { name: 'Status', as: 'radio-buttons', ...enumsForSelect(recordStatus) }
                ],
                { name: 'Address Information', as: 'section' },
                [
                  { name: 'Line 1', attributeName: 'Address.addressLine1', placeholder: 'Address Line 1' },
                  { name: 'Line 2', attributeName: 'Address.addressLine2', placeholder: 'Address Line 2' }
                ],
                [
                  { name: 'City', attributeName: 'Address.city' },
                  { name: 'State', attributeName: 'Address.stateId', as: 'select', options: '/application/states', optionValue: s => s.id, optionLabel: s => s.name }
                ],
                [
                  { name: 'Zip Code', attributeName: 'Address.zipCode' },
                  { name: 'Contact', attributeName: 'UserId', as: 'select', options: '/users/all', optionValue: u => u.id, optionLabel: u => `${u.firstName} ${u.lastName} (${u.email})`, }
                ]
              ]}
              changeMessage="In order to save, at least one field must be changed."
              model="company"
              pluralModel="companies"
              submit="update"
            />
          </Tab>
          <Tab eventKey="locations" title="Locations">
            <ModelView
              label="Location"
              model="location"
              pluralModel="locations"
              headers={['Name', 'Status', 'Destination']}
              containerClass=""
              parentId={company.id}
              cells={[
                (item) => item.name,
                (item) => <div className="status-container"><div className={`status-circle bg-${item.status === 0 ? 'success' : 'danger'}`}></div>{recordStatus[item.status]}</div>,
                (item) => item.destination.name,
              ]}
              fields={[
                { name: 'Name' },
                {
                  name: 'Destination',
                  objectName: 'destination',
                  attributeName: 'DestinationId',
                  as: 'select',
                  options: '/destinations/all',
                  optionValue: u => u.id,
                  optionLabel: u => u.name
                },
                {
                  name: 'Brochure',
                  attributeName: 'BrochureId',
                  as: 'select',
                  options: (data, search) => data.destination ? `https://www.mobilebrochure.com/${data.destination.siteUrl}/wp-json/wc/v2/products?consumer_key=${data.destination.siteKey}&consumer_secret=${data.destination.siteSecret}&per_page=5&search=${search}` : [],
                  singleUrl: (data, id) => data.destination && id ? `https://www.mobilebrochure.com/${data.destination.siteUrl}/wp-json/wc/v2/products/${id}?consumer_key=${data.destination.siteKey}&consumer_secret=${data.destination.siteSecret}` : false,
                  optionValue: u => u.id,
                  optionLabel: u => htmlEntities.decode(u.name),
                  disabled: data => !data.destination
                },
                { name: 'Status', as: 'radio-buttons', ...enumsForSelect(recordStatus) }
              ]}
              customModal={{
                title: 'Add Existing Location',
                action: 'update',
                allowSubmit: (data) => !data.location || !data.location.company || window.confirm(`Are you sure you want to reassign ${data.location.name} from ${data.location.company.name} to ${company.name}`),
                formData: (data) => data.location,
                button: {
                  icon: ['fal', 'search-plus'],
                  label: 'Add Existing Location'
                },
                fields: [{
                  name: 'Location',
                  attributeName: 'LocationId',
                  objectName: 'location',
                  options: '/locations/all',
                  optionValue: u => u.id,
                  optionLabel: u => `${u.company.name} - ${u.name}`,
                  allowOption: u => u.companyId !== company.id,
                  as: 'select'
                }]
              }}
            />
          </Tab>
          <Tab eventKey="subscriptions" title="Subscriptions">
          <ModelView
          label="Subscription"
          model="locationSubscription"
          params={`companyId=${company.id}`}
          containerClass=""
          pluralModel="locationSubscriptions"
          editSettings={item => {
            if (item) {
              return {
                buttonLabel: 'Payment Method',
                modalLabel: 'Select a Payment Method',
                fields: [
                  {
                    name: 'Payment Method', 
                    attributeName: 'PaymentMethodId',
                    as: 'select', 
                    options: `/companies/${item.location.companyId}/paymentMethods/all`, 
                    optionValue: u => u.id, 
                    optionLabel: u => `${_.startCase(u.brand)} - ${u.lastFour} (Exp ${u.expirationMonth}/${u.expirationYear})`
                  }
                ]
              }
            } else {
              return {}
            }
          }}
          headers={['Location Name', 'Next Payment', 'Subscription Plan', 'Status']}
          newLabel="Subscribe a location"
          defaultItem={{firstPaymentDate: new Date(), status: '0'}}
          cells={[
            (item) => item.location.name,
            (item) => item.nextPaymentDate ? format(new Date(item.nextPaymentDate), 'MMMM d, yyyy') : 'Cancelled',
            (item) => item.subscription.name,
            (item) => <div className="status-container"><div className={`status-circle bg-${item.pending ? 'warning' : (item.status === 0 ? 'success' : 'danger')}`}></div>{item.pending ? 'Pending' : ''} {recordStatus[item.status]}</div>,
          ]}
          fields={[
            {name: 'General Information', as: 'section'},
            {name: 'Location', objectName: 'location', attributeName: 'LocationId', as: 'select', options: `/companies/${company.id}/locations/all`, optionValue: u => u.id, optionLabel: u => `${u.name}`},
            [
              {name: 'Start Date', attributeName: 'FirstPaymentDate', placeholder: 'First Payment Date', as: 'date'},
              {name: 'Status', as: 'radio-buttons', ...enumsForSelect(recordStatus)}
            ],
            {name: 'Billing Information', as: 'section'},
            {
              name: 'Payment Method', 
              attributeName: 'PaymentMethodId',
              as: 'select', 
              defaultOption: {label: '-- Create New Payment Method --', id: ''}, 
              options: item => `/companies/${company.id}/paymentMethods/all`, 
              optionValue: u => u.id, 
              optionLabel: u => `${_.startCase(u.brand)} - ${u.lastFour} (Exp ${u.expirationMonth}/${u.expirationYear})`,
            },
            [
              {name: 'Line 1', attributeName: 'PaymentMethod.address.addressLine1', placeholder: 'Address Line 1', show: item => !item.paymentMethodId},
              {name: 'Line 2', attributeName: 'PaymentMethod.address.addressLine2', placeholder: 'Address Line 2', show: item => !item.paymentMethodId}
            ],
            [
              {name: 'City', attributeName: 'PaymentMethod.address.city', show: item => !item.paymentMethodId},
              {name: 'State', attributeName: 'PaymentMethod.address.stateId', as: 'select', options: '/application/states', optionValue: s => s.id, optionLabel: s => s.name, show: item => !item.paymentMethodId}
            ],
            [
              {name: 'Zip Code', attributeName: 'PaymentMethod.address.zipCode', show: item => !item.paymentMethodId},
              {name: 'Card', attributeName: 'PaymentMethod', as: 'card', show: item => !item.paymentMethodId}
            ]
          ]}
        />
          </Tab>
          <Tab eventKey="paymentMethods" title="Payment Methods">
            <ModelView
              label="Payment Method"
              model="paymentMethod"
              pluralModel="paymentMethods"
              headers={['Brand', 'Last 4', 'Expiration', 'Subscriptions']}
              containerClass=""
              parentId={company.id}
              cells={[
                (item) => _.startCase(item.brand),
                (item) => item.lastFour,
                (item) => `${item.expirationMonth}/${item.expirationYear}`,
                (item) => item.locationSubscriptions ? item.locationSubscriptions.length : 0,
              ]}
              disableEdit={true}
              showDelete={true}
              disableDelete={(item) => item.locationSubscriptions ? item.locationSubscriptions.length > 0 : false}
              fields={[
                { name: 'Card', attributeName: 'self', as: 'card' },
                { name: 'Billing Address', as: 'section' },
                { name: 'Line 1', attributeName: 'Address.addressLine1', placeholder: 'Address Line 1' },
                { name: 'Line 2', attributeName: 'Address.addressLine2', placeholder: 'Address Line 2' },
                [
                  { name: 'City', attributeName: 'Address.city' },
                  { name: 'State', attributeName: 'Address.stateId', as: 'select', options: '/application/states', optionValue: s => s.id, optionLabel: s => s.name },
                ],
                [
                  { name: 'Zip Code', attributeName: 'Address.zipCode' },
                ],
              ]}
            />
          </Tab>
        </Tabs>

        <ModelView
          header="Users List"
          label="User"
          model="user"
          pluralModel="users"
          headers={['Name', 'Email Address', 'Status', 'Is Admin?']}
          containerClass="content-block mt-5"
          parentId={company.id}
          cells={[
            (item) => `${item.firstName} ${item.lastName}`,
            (item) => item.email,
            (item) => <div className="status-container"><div className={`status-circle bg-${item.status === 0 ? 'success' : 'danger'}`}></div>{recordStatus[item.status]}</div>,
            (item) => item.role === 2 ? <div className="text-center"><FontAwesomeIcon icon={['far', 'check']} /></div> : null,
          ]}
          fields={[
            [
              { name: 'First Name' },
              { name: 'Last Name' }
            ],
            [
              { name: 'User Name' },
              { name: 'Phone', mask: "(###) ###-####" }
            ],
            { name: 'Email', type: 'email' },
            [
              { name: 'Role', as: 'select', ...enumsForSelect(_.pick(userRoles, ['0', '2'])) },
              { name: 'Status', as: 'radio-buttons', ...enumsForSelect(recordStatus) }
            ]
          ]}
          customModal={{
            title: 'Add Existing User',
            action: 'update',
            allowSubmit: (data) => !data.user || data.user.userCompanies.length === 0 || window.confirm(`Are you sure you want to reassign ${data.user.firstName} ${data.user.lastName} from ${data.user.userCompanies[0].company.name} to ${company.name}`),
            formData: (data) => data.user,
            button: {
              icon: ['fal', 'search-plus'],
              label: 'Add Existing User'
            },
            fields: [{
              name: 'User',
              attributeName: 'UserId',
              objectName: 'user',
              options: '/users/all',
              optionValue: u => u.id,
              optionLabel: u => `${u.firstName} ${u.lastName} (${u.email})`,
              allowOption: u => !u.userCompanies.map(x => x.companyId).flat().includes(company.id) && [0, 2].includes(u.role),
              as: 'select'
            }]
          }}
        />
      </div>
    )
  }
};

export default connect(state => ({
  companies: state.companiesReducer.results,
  loading: state.companiesReducer.loading,
}))(withRouter(CustomerManagement));
