export default {
  ACTIVATION_LOADING: 'ACTIVATION_LOADING',
  ACTIVATION_COMPLETE: 'ACTIVATION_COMPLETE',
  ACTIVATION_ERROR: 'ACTIVATION_ERROR',
  FETCHING_TOKEN: 'FETCHING_TOKEN',
  FETCHING_TOKEN_COMPLETE: 'FETCHING_TOKEN_COMPLETE',
  TOKEN_ERROR: 'TOKEN_ERROR',
  RESET_ACTIVATION_ERRORS: 'RESET_ACTIVATION_ERRORS',
  REQUESTING_RESET: 'REQUESTING_RESET',
  REQUESTING_RESET_ERROR: 'REQUESTING_RESET_ERROR',
  REQUESTING_RESET_COMPLETE: 'REQUESTING_RESET_COMPLETE'
};
