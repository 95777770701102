import _ from 'lodash'
import { logout } from 'actions/loginActions';

function handleAxiosError(e, dispatch = null, type = null) {
  try {
    const error = { ...e }
    _.isEmpty(error) ? console.log(e) : console.log(error)
    var data = { title: 'Unknown error' }

    if (error && error.response) {
      if (error.response.status !== 403) {
        if (_.isObject(error.response.data)) {
          data = error.response.data;
        } else if (error.response.statusText) {
          data = { title: `The server responded with: ${error.response.statusText}` }
        }
      } else {
        dispatch(logout("Your account does not have access to that page."))
      }
    }

    if (dispatch && type) {
      dispatch({ type, data });
    }
  } catch {
    if (dispatch && type) {
      dispatch({ type, data: { title: 'Unknown error' } });
    }
  }
}

export default handleAxiosError;
