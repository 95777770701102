import actionTypes from '../actionTypes/loginTypes';
import globalTypes from '../actionTypes/globalTypes';
import handleAxiosError from 'helpers/axiosHelpers'
import axios from 'axios';
import Cookies from 'js-cookie'

export function login(data) {
  return async dispatch => {
    dispatch({
      type: actionTypes.LOGGING_IN
    });

    try {
      const response = await axios.post('/users/login', {identity: data.identity, password: data.password})
      const {jwt} = response.data;

      axios.defaults.headers['Authorization'] = `Bearer ${jwt}`;

      dispatch({
        type: actionTypes.LOGGING_IN_COMPLETE,
        loggedInUser: response.data
      });

      dispatch({
        type: globalTypes.SET_CONFIRMATION_MESSAGE,
        message: `Logged in successfully`
      });

      Cookies.set('token', jwt, { expires: 14, path: '/' });
    } catch (e) {
      handleAxiosError(e, dispatch, actionTypes.LOGGING_IN_ERROR)
    }
  }
}

export function resetLoginErrors() {
  return async dispatch => {
    dispatch({
      type: actionTypes.RESET_LOGGING_IN_ERRORS
    })
  }
}

export function checkLogin() {
  return async dispatch => {
    if (axios.defaults.headers['Authorization']) {
      try {
        const response = await axios.get('/users/profile')

        dispatch({
          type: actionTypes.LOGGING_IN_COMPLETE,
          loggedInUser: response.data
        });
      } catch (e) {
        handleAxiosError(e)
      }
    }

    dispatch({
      type: actionTypes.LOGIN_CHECK_COMPLETE
    })
  }
}

export function logout(message = 'Logged out successfully') {
  return async dispatch => {
    Cookies.remove('token', { expires: 365, path: '/' });
    delete axios.defaults.headers['Authorization']

    dispatch({
      type: globalTypes.SET_CONFIRMATION_MESSAGE,
      message: message
    })

    dispatch({
      type: actionTypes.LOGOUT
    });
  }
}