import React, { Component } from 'react'
import ModelView from 'components/modelView'
import { recordStatus, enumsForSelect } from 'utils/config';

class Companies extends Component {
  render() {
    return (
      <ModelView 
        icon={['far', 'building']}
        title="Customer Management"
        label="Customer"
        model="company"
        pluralModel="companies"
        manage={true}
        headers={['Name', 'Locations', 'Users', 'Status', 'Email']}
        cells={[
          (item) => item.name,
          (item) => item.locations ? item.locations.length : 0,
          (item) => item.userCompanies ? item.userCompanies.length : 0,
          (item) => <div className="status-container"><div className={`status-circle bg-${item.status === 0 ? 'success' : 'danger'}`}></div>{recordStatus[item.status]}</div>,
          (item) => item.email,
        ]}
        fields={[
          {name: 'Company Information', as: 'section'},
          [
            {name: 'Name', placeholder: 'Company Name'},
            {name: 'Email'}
          ],
          [
            {name: 'Phone', mask: "(###) ###-####"},
            {name: 'Status', as: 'radio-buttons', ...enumsForSelect(recordStatus)}
          ],
          {name: 'Address Information', as: 'section'},
          [
            {name: 'Line 1', attributeName: 'Address.addressLine1', placeholder: 'Address Line 1'},
            {name: 'Line 2', attributeName: 'Address.addressLine2', placeholder: 'Address Line 2'}
          ],
          [
            {name: 'City', attributeName: 'Address.city'},
            {name: 'State', attributeName: 'Address.stateId', as: 'select', options: '/application/states', optionValue: s => s.id, optionLabel: s => s.name}
          ],
          [
            {name: 'Zip Code', attributeName: 'Address.zipCode'},
            {name: 'Contact', attributeName: 'UserId', as: 'select', options: '/users/all', optionValue: u => u.id, optionLabel: u => `${u.firstName} ${u.lastName} (${u.email})`}
          ],
        ]}
      />
    )
  }
};

export default Companies
