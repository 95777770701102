import ActionTypes from '../actionTypes/profileTypes';

const initialState = {
  updating: false,
  updateError: false,
  updatingPassword: false,
  updatePasswordError: false,
}

function profileReducer (state = initialState, action) {
    switch(action.type) {
      case ActionTypes.UPDATING_PROFILE: {
        return { ...state, updating: true, updateError: false };
      }
      case ActionTypes.UPDATING_PROFILE_COMPLETE: {
        return { ...state, updateError: false, updating: false };
      }
      case ActionTypes.UPDATING_PROFILE_ERROR: {
        return { ...state, updateError: action.data, updating: false };
      }
      case ActionTypes.UPDATING_PASSWORD: {
        return { ...state, updatePasswordError: false, updatingPassword: true };
      }
      case ActionTypes.UPDATING_PASSWORD_COMPLETE: {
        return { ...state, updatingPassword: false, updatePasswordError: false };
      }
      case ActionTypes.UPDATING_PASSWORD_ERROR: {
        return { ...state, updatePasswordError: action.data, updatingPassword: false };
      }
      case ActionTypes.RESET_ERRORS: {
        return { ...state, updateError: false, updatePasswordError: false };
      }
      default: {
        return state;
      }
    }
}

export default profileReducer;