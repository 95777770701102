import React, { Component } from 'react'
import ModelView from 'components/modelView'
import { formatter, recordStatus, subscriptionInterval, subscriptionIntervalAlt, subscriptionType, enumsForSelect } from 'utils/config';
import { connect } from 'react-redux'
import { format } from 'date-fns'
import _ from "lodash";
import {withRouter} from "react-router-dom";

class Subscriptions extends Component {
  render() {
    if ([3, 1].includes(this.props.loggedInUser.role)) {
      return (
        <ModelView 
          icon={['far', 'list']}
          title="Subscriptions Management"
          label="Subscription Plan"
          model="subscription"
          pluralModel="subscriptions"
          manage={true}
          headers={['Name', 'Status', 'Interval', 'Subscribers', 'Cost Per Interval']}
          cells={[
            (item) => item.name,
            (item) => <div className="status-container"><div className={`status-circle bg-${item.status === 0 ? 'success' : 'danger'}`}></div>{recordStatus[item.status]}</div>,
            (item) => `Every ${item.subscriptionIntervalCount} ${subscriptionIntervalAlt[item.subscriptionInterval]}${item.subscriptionIntervalCount > 1 ? 's' : ''}`,
            (item) => item.locationSubscriptions ? item.locationSubscriptions.length : 0,
            (item) => formatter.format(item.cost),
          ]}
          defaultItem={{subscriptionIntervalCount: 1}}
          toggleStatusDisabled={(item) => item.locationSubscriptions ? item.locationSubscriptions.length > 0 : false}
          fields={[
            [
              {name: 'Name'},
              {name: 'Cost'}
            ],
            [
              {name: 'Interval', attributeName: 'SubscriptionInterval', as: 'select', ...enumsForSelect(subscriptionInterval, true)},
              {name: 'Frequency', attributeName: 'SubscriptionIntervalCount'}
            ],
            [
              {name: 'Type', attributeName: 'SubscriptionType', as: 'select', ...enumsForSelect(subscriptionType, true)},
              {name: 'Status', as: 'radio-buttons', ...enumsForSelect(recordStatus)}
            ]
          ]}
          groupBy={(item) => `${subscriptionType[item.subscriptionType]} Subscription`}
        />
      )
    } else {
      return (
        <ModelView 
          icon={['far', 'list']}
          title="Subscriptions Management"
          label="Subscription"
          model="locationSubscription"
          pluralModel="locationSubscriptions"
          editSettings={item => {
            if (item) {
              return {
                buttonLabel: 'Payment Method',
                modalLabel: 'Select a Payment Method',
                fields: [
                  {
                    name: 'Payment Method', 
                    attributeName: 'PaymentMethodId',
                    as: 'select', 
                    options: `/companies/${item.location.companyId}/paymentMethods/all`, 
                    optionValue: u => u.id, 
                    optionLabel: u => `${_.startCase(u.brand)} - ${u.lastFour} (Exp ${u.expirationMonth}/${u.expirationYear})`
                  }
                ]
              }
            } else {
              return {}
            }
          }}
          headers={['Location Name', 'Next Payment', 'Subscription Plan', 'Status', 'Destination']}
          newLabel="Subscribe a location"
          defaultItem={{firstPaymentDate: new Date(), status: '0'}}
          toggleStatusDisabled={(item) => item.pending}
          cells={[
            (item) => item.location.name,
            (item) => item.nextPaymentDate ? format(new Date(item.nextPaymentDate), 'MMMM d, yyyy') : 'Cancelled',
            (item) => item.subscription.name,
            (item) => <div className="status-container"><div className={`status-circle bg-${item.pending ? 'warning' : (item.status === 0 ? 'success' : 'danger')}`}></div>{item.pending ? 'Pending' : ''} {recordStatus[item.status]}</div>,
            (item) => item.location.destination.name,
          ]}
          fields={[
            {name: 'General Information', as: 'section'},
            [
              {name: 'Location', objectName: 'location', attributeName: 'LocationId', as: 'select', options: '/locations/all', optionValue: u => u.id, optionLabel: u => u.name},
              {name: 'Plan', attributeName: 'SubscriptionId', as: 'select', options: '/subscriptions/all', optionValue: u => u.id, optionLabel: u => `${u.name} - ${u.cost}`},
            ],
            [
              {name: 'Start Date', attributeName: 'FirstPaymentDate', placeholder: 'First Payment Date', as: 'date', disabled: true},
              {name: 'Status', as: 'radio-buttons', ...enumsForSelect(recordStatus)}
            ],
            {name: 'Billing Information', as: 'section'},
            {
              name: 'Payment Method', 
              attributeName: 'PaymentMethodId',
              as: 'select', 
              defaultOption: {label: '-- Create New Payment Method --', id: ''}, 
              options: item => item.location ? `/companies/${item.location.companyId}/paymentMethods/all` : [], 
              optionValue: u => u.id, 
              optionLabel: u => `${_.startCase(u.brand)} - ${u.lastFour} (Exp ${u.expirationMonth}/${u.expirationYear})`,
            },
            [
              {name: 'Line 1', attributeName: 'PaymentMethod.address.addressLine1', placeholder: 'Address Line 1', show: item => !item.paymentMethodId},
              {name: 'Line 2', attributeName: 'PaymentMethod.address.addressLine2', placeholder: 'Address Line 2', show: item => !item.paymentMethodId}
            ],
            [
              {name: 'City', attributeName: 'PaymentMethod.address.city', show: item => !item.paymentMethodId},
              {name: 'State', attributeName: 'PaymentMethod.address.stateId', as: 'select', options: '/application/states', optionValue: s => s.id, optionLabel: s => s.name, show: item => !item.paymentMethodId}
            ],
            [
              {name: 'Zip Code', attributeName: 'PaymentMethod.address.zipCode', show: item => !item.paymentMethodId},
              {name: 'Card', attributeName: 'PaymentMethod', as: 'card', show: item => !item.paymentMethodId}
            ]
          ]}
        />
      )
    }
  }
};

export default connect(state => ({
  loggedInUser: state.loginReducer.loggedInUser,
}))(withRouter(Subscriptions));
