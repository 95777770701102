import ActionTypes from 'actionTypes/globalTypes';

const initialState = {
  fetchingBrochureUrl: false,
  brochureUrlError: false,
  brochureUrl: null,
  confirmationMessage: ''
};

function globalReducer(state = initialState, action) {
  switch(action.type) {
    case ActionTypes.SET_CONFIRMATION_MESSAGE: {
      return {...state, confirmationMessage: action.message};
    }
    case ActionTypes.FETCHING_BROCHURE_URL: {
      return {...state, fetchingBrochureUrl: true, brochureUrlError: false};
    }
    case ActionTypes.BROCHURE_URL_COMPLETE: {
      return {...state, fetchingBrochureUrl: false, brochureUrlError: false, brochureUrl: action.data};
    }
    case ActionTypes.BROCHURE_URL_ERROR: {
      return {...state, brochureUrlError: action.data, fetchingBrochureUrl: false};
    }
    default:
      return state;
  }
}

export default globalReducer;